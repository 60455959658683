import { defaultPitch } from './pitch'
import { rugbyLeagueEventTypes } from './events'
import { outcomeTypes } from '../../outcomes/data_types'
import { pitchSetupTypes } from '../../pitches/data_types'
import { FormattedEventData } from '../../events/types'
import { FlightEventTypeGroup } from '../../events/flight/data_types'

export const rugbyLeagueProps = {
  eventTypes: rugbyLeagueEventTypes,
  pitch: {
    default: defaultPitch,
    setupTypes: pitchSetupTypes,
    minPadding2D: 70,
    minPadding3D: 50,
    cameraYScale: 0.5,
    textureCanvasScale: 11,
    tenYardMarkings: 10,
    crossfieldLines: [
      { name: 'Try line', lower: 'P2', upper: 'P29' },
      { name: '10 meter line', lower: 'P3', upper: 'P28' },
      { name: '20 meter line', lower: 'P4', upper: 'P27' },
      { name: '30 meter line', lower: 'P5', upper: 'P26' },
      { name: '40 meter line', lower: 'P6', upper: 'P25' },
      { name: 'Halfway line', lower: 'P7', upper: 'P24' },
      { name: '40 meter line', lower: 'P8', upper: 'P23' },
      { name: '30 meter line', lower: 'P9', upper: 'P22' },
      { name: '20 meter line', lower: 'P10', upper: 'P21' },
      { name: '10 meter line', lower: 'P11', upper: 'P20' },
      { name: 'Try line', lower: 'P12', upper: 'P19' }
    ],
    enableBench: true
  },
  features: {
    targetEvents: ['kick'],
    positionNumbers: true,
    maxPlayers: 17,
    defaultUnitSystem: 'British',
    keyboardShortcuts: {
      screenName: 'Validation - Kick Detail Card',
      shortcuts: {
        u: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.kick?.value
            )
          },
          outcome: outcomeTypes.items.uncontested.value
        },
        c: {
          condition: (
            formattedEvent: FormattedEventData,
            flightTypes: FlightEventTypeGroup
          ) => {
            return (
              formattedEvent &&
              formattedEvent.type.selected?.value ===
                flightTypes.items.kick?.value
            )
          },
          outcome: outcomeTypes.items.contestedLost.value
        }
      }
    },
    recentEventTableMetrics: [
      'inPitchDistance',
      'inPitchHangTime',
      'speed',
      'territorialGain'
    ],
    broadcastIntegrationAvailable: false,
    noOfPlayersInStartingLineUp: null,
    scoreboard: false,
    homeSideCheck: false
  } as const
}
