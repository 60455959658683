import React from 'react'
import { FormattedDevice } from '../../../../metrics_server/hardware/types'
import styles from '../HardwareItem.module.scss'
import { BatteryIcon } from '../../../Icons/BatteryIcon/BatteryIcon'
import { TagIcon } from '../../../Icons/TagIcon/TagIcon'
import { sportableColors } from '../../../../const'
import {
  useFormattedSession,
  useTagAssignmentDragAndDrop
} from '../../../../metrics_server/sessions/hooks'
import { QAInfo } from '../QAInfo/QAInfo'
import { HardwareCommon } from '../HardwareCommon/HardwareCommon'
import { useAppDispatch } from '../../../../store/hooks'
import { removeTagFromPlayer } from '../../../../metrics_server/sessions/actions'

export interface HardwareTagProps {
  device: FormattedDevice
  isSessionAssignment: boolean
}

export const HardwareTag: React.FC<HardwareTagProps> = (props) => {
  const dispatch = useAppDispatch()
  const { isSessionAssignment, device } = props

  const {
    onDragLeave,
    onDragOver,
    onTagDrop,
    onTagDrag,
    dropZone,
    isDragged,
    currentTagId
  } = useTagAssignmentDragAndDrop('setup')

  const { playersSessions } = useFormattedSession('setup')

  if (!isSessionAssignment) return <HardwareCommon device={device} />

  // Get player session
  const playerSession = playersSessions.byHardwareId.map[device.id]
  const handleDragStart = (e) => {
    // Check if the target or its ancestor is the shared container
    const isTagOrChild = e.target.closest(`.${styles.sharedContainer}`) !== null

    if (isTagOrChild) {
      const id = e.target.id
      onTagDrag({
        ...e,
        target: {
          ...e.target,
          id: id ? id : device.id // Use device.id instead of e.target.id
        }
      })
    } else {
      e.preventDefault()
    }
  }
  if (!playerSession) {
    if (isDragged && parseInt(currentTagId) === device.id) {
      return (
        <div id={`${device.id}`} className={`${styles.container}`}>
          <div
            className={`${styles.sharedContainer}`}
            draggable={true}
            onDragStart={(e) => handleDragStart(e)}
          >
            <div className={styles.encodedId} draggable={false}>
              <div className={styles.batteryContainer}>
                <BatteryIcon device={device} />
              </div>
              {device.serial}
            </div>
            <TagIcon scale={0.8} strokeWidth={2.5} />
            <div style={{ alignSelf: 'flex-end', minHeight: '20px' }} />
          </div>
        </div>
      )
    } else {
      return (
        <div className={`${styles.container}`}>
          <div
            className={`${styles.sharedContainer}`}
            id={`${device.id}`}
            draggable={true}
            onDragStart={(e) => handleDragStart(e)}
          >
            <div className={styles.encodedId}>
              <div className={styles.batteryContainer}>
                <BatteryIcon device={device} />
              </div>
              {device.serial}
            </div>
            <TagIcon scale={0.8} strokeWidth={2.5} />
            <QAInfo qaReports={device.reports} />
          </div>
        </div>
      )
    }
  } else {
    return (
      <div className={`${styles.container}`}>
        <div
          onDragOver={(e) => onDragOver(e)}
          onDragLeave={(e) => onDragLeave(e)}
          onDrop={(e) => onTagDrop(e, playerSession.playerId, null)}
          onClick={() => dispatch(removeTagFromPlayer(playerSession))}
          className={`${styles.sharedContainer}
              ${styles.linkedBorder}
              ${dropZone === device.id && styles.activeDrop}`}
        >
          <div
            id={`${device.id}`}
            data-playerid={playerSession.playerId}
            className={`${styles.onDrop} tagDropZone`}
          ></div>
          <div className={styles.encodedId}>
            <div className={styles.batteryContainer}>
              <BatteryIcon device={device} />
            </div>
            {device.serial}
          </div>
          <TagIcon
            scale={0.9}
            color={sportableColors.colors.sportableBlue}
            strokeWidth={2.5}
          />
          <QAInfo qaReports={device.reports} />
        </div>
        <div id='player-name' className={styles.playerName}>
          <div className={styles.tagInfo}>
            {playerSession.player?.abbreviatedName}
          </div>
        </div>
      </div>
    )
  }
}
