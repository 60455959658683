import React, { useEffect, useState } from 'react'
import { useAppDispatch } from '../../../../store/hooks'
import { setBallInPlay } from '../../../../metrics_server/events/actions'
import styles from './InfoContainer.module.scss'
import { Strack } from '../../Strack.types'
import {
  useFormattedHardware,
  useHardware
} from '../../../../metrics_server/hardware/hooks'
import { isDeviceType } from '../../../../metrics_server/hardware/data_types'
import { BatteryIcon } from '../../../Icons/BatteryIcon/BatteryIcon'
import { SessionTagInfo } from './SessionTagInfo'
import { serviceAction } from '../../../../metrics_server/hardware/actions'
import { Button } from '../../../Button/Button'
import { setSelectedDevice } from '../../../../metrics_server/hardware/functions'

export interface StrackTagInfoProps {
  strackReady: boolean
  strack: Strack
}

export function StrackTagInfo({ strackReady, strack }: StrackTagInfoProps) {
  const dispatch = useAppDispatch()
  const formattedHardware = useFormattedHardware()

  const { selectedDevice } = useHardware()

  const [isWaking, setIsWaking] = useState(false)
  const [isSleeping, setIsSleeping] = useState(false)

  // Listen for tag click //
  useEffect(() => {
    if (strackReady) {
      strack.events.eventHandleCanvas.addEventListener('click', (e) => {
        strack.checkForTagOnClick(e, (tagId) => {
          strack.setSelectedTag(tagId)
          dispatch(setSelectedDevice(tagId))
        })
      })
    }
  }, [strackReady])

  useEffect(() => {
    if (strackReady) {
      strack.setSelectedTag(selectedDevice)
    }
  }, [selectedDevice])

  const handleWakeClick = () => {
    if (device && !isWaking) {
      setIsWaking(true)

      // Ensure the loading state is shown for at least 500 milliseconds even if request/response is instant
      setTimeout(() => {
        dispatch(serviceAction([device.id], 'power/promote', true)).finally(
          () => {
            setIsWaking(false)
          }
        )
      }, 500)
    }
  }

  const handleSleepClick = () => {
    if (device && !isSleeping) {
      setIsSleeping(true)
      // Ensure the loading state is shown for at least 500 milliseconds even if request/response is instant
      setTimeout(() => {
        dispatch(serviceAction([device.id], 'power/demote', true)).finally(
          () => {
            setIsSleeping(false)
          }
        )
      }, 500)
    }
  }

  if (!selectedDevice) return null

  const device = formattedHardware.devices.map[selectedDevice]

  if (!device)
    return (
      <div className={styles.tagInfoContainer}>
        <h5>device not found </h5>
      </div>
    )

  return (
    <div className={styles.tagInfoContainer}>
      <h5>
        {device.type.name} - {device && device.serial}
      </h5>
      {/* {!strack.operatorControls && ( */}
      <h5>state: {device ? device.state : '-'}</h5>
      {/* )} */}
      <div className={styles.batteryAndButton}>
        <div className={styles.battery}>
          <BatteryIcon device={device} />
          <h5>{device && device.battery.value}%</h5>
        </div>
        {isDeviceType.ball(device.type) && (
          <button
            className={`${styles.ballInPlayButton}`}
            onClick={() => {
              if (device) {
                dispatch(setBallInPlay(device.id))
              } else {
                console.log('Device info not found not found')
              }
            }}
          >
            Set ball in play
          </button>
        )}
      </div>
      {strack.session && <SessionTagInfo tagId={device.id} strack={strack} />}
      {device && (
        <>
          <></>
          {true ? (
            <div className={styles.tagInfoBtnsContainer}>
              <Button
                className={styles.wakeBtn}
                handleClick={() => {
                  if (device) {
                    handleWakeClick()
                  }
                }}
                loading={isWaking}
                loaderColor='white'
              >
                {strack.operatorControls ? 'Wake' : 'Promote'}
              </Button>
              <Button
                className={styles.sleepBtn}
                handleClick={() => {
                  if (device) {
                    handleSleepClick()
                  }
                }}
                loading={isSleeping}
                loaderColor='white'
              >
                {strack.operatorControls ? 'Sleep' : 'Demote'}
              </Button>
            </div>
          ) : (
            <div className={styles.tagInfoBtnsContainer}>
              <Button
                className={styles.wakeBtn}
                handleClick={() => {
                  if (device) {
                    dispatch(
                      serviceAction(
                        [device.id],
                        device.greyListed ? 'unGreyList' : 'greyList',
                        true
                      )
                    )
                  }
                }}
              >
                {device.greyListed ? 'Wake up' : 'Sleep'}
              </Button>
              <Button
                className={styles.sleepBtn}
                handleClick={() => {
                  if (device) {
                    dispatch(
                      serviceAction(
                        [device.id],
                        device.status.value !== 'offline'
                          ? 'shutdown'
                          : 'unseat',
                        true
                      )
                    )
                  }
                }}
              >
                {device.status.value !== 'offline' ? 'Shutdown' : 'Unseat'}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}
