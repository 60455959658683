import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { EventsFilters } from '../filter'
import { setSelectedFlight, updateEvent } from '../actions'
import {
  getCoreRowModel,
  getSortedRowModel,
  SortingState,
  useReactTable
} from '@tanstack/react-table'
import {
  useEvents,
  useFormattedEvents,
  useSelectedFormattedEvent
} from '../hooks'
import { useAppDispatch } from '../../../store/hooks'
import { useColumnSelectModal } from '../../../views/Session/modals/column_select'
import { getMostRecentKick } from '../flight/functions'
import { useSelectedFormattedSession } from '../../sessions/hooks'
import { useUnitsSystem } from '../../units/hooks'
import { TableRow } from '../../../components/Table/Table.types'
import { NewTable } from '../../../components/Table/NewTable'
import { getMetricHeaders } from '../../units/functions'
import Checkbox from '../../../components/Forms/Inputs/Checkbox/Checkbox'
import { TeamLogo } from '../../../components/TeamLogo/TeamLogo'
import { dataTypes } from '../../../components/Table/data_types'
import { RawEventData } from '../types'
import useKeyboardShortcut from 'use-keyboard-shortcut'
import { isSportType } from '../../sports/data_types'
import { isSubSessionType } from '../../sessions/sub_sessions/data_types'
import { flightEventTypes } from '../flight/data_types'
import { gameEventTypes } from '../game/data_types'

export interface ValidationTableProps {
  eventsFilters: EventsFilters
  tableId: string
  active: boolean
  updateDisplayedEvents?: (events: RawEventData[]) => void
  maxDisplayedEvents?: number
  validationTableSorting?: SortingState
  setValidationTableSorting?: (sorting: SortingState) => void
  isKeyboardShortcutEnabled?: boolean
}

export const ValidationTable = ({
  eventsFilters,
  tableId,
  active,
  updateDisplayedEvents,
  maxDisplayedEvents,
  validationTableSorting,
  setValidationTableSorting,
  isKeyboardShortcutEnabled
}: ValidationTableProps) => {
  // Modals //
  const { openColumnSelectModal } = useColumnSelectModal()
  const formattedSession = useSelectedFormattedSession()

  const { sport, id, flightMetrics, flightTypes } = formattedSession
  const formattedEvents = useFormattedEvents(eventsFilters.filteredEvents, id)
  const formattedEvent = useSelectedFormattedEvent()
  // ====== //

  // Redux //
  const events = useEvents()
  const unitSystem = useUnitsSystem(sport)
  // ====== //

  const dispatch = useAppDispatch()

  const { eventsFilter, filteredEvents, latestEvent } = eventsFilters
  // Set most recent kick as selected flight
  const selectedMostRecentKick = useCallback(() => {
    const mostRecentKick = getMostRecentKick(filteredEvents)
    if (mostRecentKick) {
      dispatch(setSelectedFlight(mostRecentKick.id))
    }
    return mostRecentKick
  }, [filteredEvents])

  const [timeColumn, setTimeColumn] = useState<
    'sessionStartTime' | 'startTimeMil'
  >('sessionStartTime')

  // const tableHeaders = useValidationTableHeaders(timeColumn)
  const selectedColumns = useMemo(() => {
    return getMetricHeaders(events.columns, flightMetrics, unitSystem, 12.5, 12)
  }, [events.columns, unitSystem])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'operator',
        header: '',
        size: 5,
        minSize: 5,
        enableResizing: true,
        cell: ({ row }) => {
          const item = row.original

          const handleCheckboxChange = (value) => {
            dispatch(
              updateEvent({
                id: item.id,
                operatorNotes: { ...item.operatorNotes, highlight: value }
              })
            )
          }
          const value = item.highlight
          return (
            <Checkbox
              size='small'
              onClicked={handleCheckboxChange}
              checked={value}
              input={{ value }}
              type='highlight'
            />
          )
        }
      },
      {
        accessorKey: 'time',
        header: 'Time',
        size: 14,
        minSize: 14,
        cell: ({ row }) => {
          const item = row.original
          const type = timeColumn === 'sessionStartTime' ? 'time' : 'date'

          const formattedTime = dataTypes[type].display(item.time)

          return formattedTime
        }
      },
      {
        accessorKey: 'team',
        header: '',
        size: 5,
        minSize: 5,
        cell: ({ row }) => {
          const item = row.original
          if (!item.teamLogo) return
          return (
            <TeamLogo
              color={item.teamLogo?.color}
              logo={item.teamLogo?.logo}
              size={16}
            />
          )
        }
      },
      {
        accessorKey: 'player',
        header: 'Player',
        size: 26.5
      },
      {
        accessorKey: 'typeName',
        header: 'Type',
        size: 19.5
      },
      ...selectedColumns,
      {
        accessorKey: 'ignore',
        header: 'Ignored',
        size: 5,
        minSize: 5,
        cell: ({ row }) => {
          const item = row.original
          const value = item.ignore

          const handleCheckboxChange = () => {
            dispatch(updateEvent({ id: item.id, ignore: !item.ignore }))
          }
          return (
            <Checkbox
              size='small'
              onClicked={handleCheckboxChange}
              checked={value}
              input={{ value }}
              type='checkbox'
              disabled={item.__disabled?.[item.key]}
            />
          )
        }
      }
    ],
    [eventsFilters.filteredEvents, timeColumn, selectedColumns]
  )

  const newTableData = useMemo(() => {
    return formattedEvents.list.map((event) => {
      const displayMetric = {}
      Object.keys(event.metrics).forEach((metricKey) => {
        const metric = event.metrics[metricKey]
        displayMetric[metricKey] = metric.display
      })
      return {
        id: event.id,
        rawData: event.rawData,
        player: event.player.selected?.nameAndNumber,
        team: event.team.selected?.name,
        teamLogo: event.team.selected,
        operator: event.operator,
        highlight: event.operator?.highlight,
        outcome: event.outcome?.selected?.name,
        time:
          timeColumn === 'sessionStartTime'
            ? event.sessionStartTime
            : event.startTime * 1000,
        typeName: event.typeName,
        ignore: event.ignore,
        __color: event.ignore ? 'rgba(0, 0, 0, 0.2)' : '#323233',
        ...displayMetric
      }
    })
  }, [formattedEvents.list, timeColumn])

  const table = useReactTable({
    data: newTableData,
    columns,
    state: {
      sorting: validationTableSorting
    },
    onSortingChange: setValidationTableSorting,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel()
    // debugTable: true
  })

  const { flatRows } = table.getRowModel()

  // Update displayed events on table page change
  useEffect(() => {
    if (!updateDisplayedEvents) return
    // Filter out first maxDisplayedEvents unignored events //
    let count = 0
    const firstMaxRows = flatRows.filter((row, i) => {
      if (!row.original.ignore && count < maxDisplayedEvents) {
        count++
        return true
      }
      return false
    })
    let rawEvents = firstMaxRows.map((row) => row.original.rawData)
    if (formattedEvent) rawEvents = [...rawEvents, formattedEvent.rawData]
    updateDisplayedEvents(rawEvents)
  }, [
    flatRows,
    updateDisplayedEvents,
    maxDisplayedEvents,
    active,
    formattedEvent,
    validationTableSorting
  ])

  // Set most recent Pass as selected flight (CFL)
  useEffect(() => {
    if (!latestEvent) {
      return
    }
    const isPass =
      latestEvent.type === flightEventTypes.items.pass.value ||
      latestEvent.type === gameEventTypes.items.manualPass.value
    const isBroadcast = isSubSessionType.broadcast(formattedSession.subType)
    const isTest = isSubSessionType.test(formattedSession.subType)
    const isCFL = isSportType.canadianFootball(sport)
    if (isPass && (isBroadcast || isTest) && isCFL && !latestEvent.ignore) {
      dispatch(setSelectedFlight(latestEvent.id))
    }
  }, [latestEvent, formattedSession, sport])

  // Generate table controls //
  const controls = useMemo(() => {
    return [
      {
        name: 'Toggle Time',
        callback: () => {
          timeColumn === 'sessionStartTime'
            ? setTimeColumn('startTimeMil')
            : setTimeColumn('sessionStartTime')
        }
      },
      {
        name: !eventsFilter.filters.ignored.value
          ? 'Show Ignored'
          : 'Show Only Valid',
        callback: () => {
          eventsFilter.updateFilterValue(
            'ignored',
            !eventsFilter.filters.ignored.value
          )
        }
      },
      {
        name: eventsFilter.filters.highlighted.value
          ? 'Show All'
          : 'Show Key Events',
        callback: () => {
          eventsFilter.updateFilterValue(
            'highlighted',
            !eventsFilter.filters.highlighted.value
          )
        }
      },
      {
        name: 'Add/Remove Metrics',
        callback: () => openColumnSelectModal(2)
      }
    ]
  }, [eventsFilter, timeColumn, openColumnSelectModal])

  // TAB favourite star
  const updateFavouriteHotKey = useCallback(() => {
    if (active && isKeyboardShortcutEnabled) {
      if (formattedEvent && 'operatorNotes' in formattedEvent.rawData) {
        const data = { ...formattedEvent.rawData }
        if (data.operatorNotes) {
          data.operatorNotes.highlight = !data.operatorNotes.highlight
        } else {
          data.operatorNotes = {
            id: null,
            highlight: true,
            notes: '',
            matchTime: null
          }
        }
        dispatch(updateEvent(data))
      }
    }
  }, [formattedEvent])

  useKeyboardShortcut(['f'], updateFavouriteHotKey, {
    overrideSystem: isKeyboardShortcutEnabled
  })

  // Tab shortcut - ignore event
  const updateIgnoreCheckboxHotKey = useCallback(() => {
    if (active && isKeyboardShortcutEnabled) {
      if (formattedEvent && 'ignore' in formattedEvent.rawData) {
        const data = {
          id: formattedEvent.rawData.id,
          ignore: !formattedEvent.rawData.ignore
        }
        dispatch(updateEvent(data))
      }
    }
  }, [formattedEvent])

  useKeyboardShortcut(['Tab'], updateIgnoreCheckboxHotKey, {
    overrideSystem: isKeyboardShortcutEnabled
  })

  return (
    <NewTable
      active={active}
      table={table}
      title={`${filteredEvents.length} Events`}
      handleShiftUpShortcut={selectedMostRecentKick}
      controls={controls}
    />

    // <Table
    //   // Row props
    //   // pagination={sportTypes.isType('canadianFootball', sport.value)}
    //   pagination={false}
    //   highlightRow={(tableId, item) => {
    //     dispatch(setSelectedFlight(item.id))
    //   }}
    //   highlightedRow={highlightedRowId}
    //   onPageChange={updateDisplayedEvents}
    //   id={tableId}
    //   // controls
    //   controls={[
    //     {
    //       name: 'Toggle Time',
    //       callback: () => {
    //         timeColumn === 'sessionStartTime'
    //           ? setTimeColumn('startTimeMil')
    //           : setTimeColumn('sessionStartTime')
    //       }
    //     },
    //     {
    //       name: !eventsFilter.filters.ignored.value
    //         ? 'Show Ignored'
    //         : 'Show Only Valid',
    //       callback: () => {
    //         eventsFilter.updateFilterValue(
    //           'ignored',
    //           !eventsFilter.filters.ignored.value
    //         )
    //       }
    //     },
    //     {
    //       name: eventsFilter.filters.highlighted.value
    //         ? 'Show All'
    //         : 'Show Key Events',
    //       callback: () => {
    //         eventsFilter.updateFilterValue(
    //           'highlighted',
    //           !eventsFilter.filters.highlighted.value
    //         )
    //       }
    //     },
    //     {
    //       name: 'Add/Remove Metrics',
    //       callback: () => openColumnSelectModal(2)
    //     }
    //   ]}
    //   title={`${filteredEvents.length} Events`}
    //   // Table props
    //   options={{
    //     initialOrder: live ? 'dec' : 'asc',
    //     initialSortBy: 'sessionStartTime',
    //     sortActive: true
    //   }}
    //   scrollShortcutsEnabled={active}
    //   handleShiftUpShortcut={selectedMostRecentKick}
    //   headerFont={13}
    //   tableClass={'minimalistBlack'}
    //   className={'container'}
    //   data={eventsFilters.filteredEvents}
    //   headers={tableHeaders}
    //   generateRowData={(item: RawEventData) => {
    //     const formattedEvent = formatEventData(
    //       item,
    //       formattedSession,
    //       unitSystem
    //     )
    //     const row = generateEventRowData(
    //       formattedEvent,
    //       formattedSession,
    //       unitSystem
    //     )
    //     return row
    //   }}
    // />
  )
}
