import React from 'react'
import { Button } from '../../../components/Button/Button'
import { FormContainer } from '../../../components/Forms/Form/Form'
import { OrganisationsState } from '../../../metrics_server/organisations/types'
import { TeamsState } from '../../../metrics_server/teams/types'
import { UserState } from '../../../metrics_server/user/types'

import styles from '../../../components/Forms/Form/Form.module.scss'
import TextInput from '../../../components/Forms/Inputs/TextInput/TextInput'
import { OrganisationAndTeamForms } from '../form_config'

export interface FormValidationErrors {
  [key: string]: string | undefined
}

const validate = (values) => {
  const errors: FormValidationErrors = {}
  if (!values.firstWord) {
    errors.firstWord = 'First word is required'
  }
  if (!values.secondWord) {
    errors.secondWord = 'Second word is required'
  }
  if (!values.thirdWord) {
    errors.thirdWord = 'Third word is required'
  }

  return errors
}

export type FormIndexType = {
  createOrganisation: 0
  joinOrganisation: 1
  createTeam: 2
  joinTeam: 3
}

export interface JoinOrganisationFormProps {
  user: UserState
  change: (field: string, value: any) => void
  checkOrganisation: (values, switchToNextForm) => void
  signoutUser: () => void
  organisations: OrganisationsState | undefined
  switchForm: (formKey: string) => void
  joinTeam: (team, switchToNextForm) => void
  teams: TeamsState
  formIndex: FormIndexType
  goBack: () => void
  setRedirect: (path: string) => void
}

export const JoinOrganisationForm = (props: JoinOrganisationFormProps) => {
  const handleSubmit = (values) => {
    props.checkOrganisation(values, switchToNextForm)
  }

  const switchToNextForm = () => {
    props.switchForm(OrganisationAndTeamForms.joinTeam)
  }

  const fields = [
    {
      name: 'firstWord',
      type: 'text',
      label: 'First word',
      component: TextInput
    },
    {
      name: 'secondWord',
      type: 'text',
      label: 'Second word',
      component: TextInput
    },
    {
      name: 'thirdWord',
      type: 'text',
      label: 'Third word',
      component: TextInput
    }
  ]

  return (
    <>
      <FormContainer
        fields={fields}
        validate={validate}
        onSubmit={(values) => handleSubmit(values)}
        submitText={'Find'}
        title={'Join An Organisation'}
        message={'Enter the 3-word password to join an organisation'}
      />
      <div className={styles.buttonsContainer}>
        <Button
          handleClick={props.signoutUser}
          className='btn--plain'
          style={{ overflow: 'hidden' }}
        >
          Sign in with different account
        </Button>
      </div>
    </>
  )
}
