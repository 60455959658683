import {
  UPDATE_HARDWARE,
  TOGGLE_IGNORE_TAG_FOR_SLEEP_AND_WAKE_ALL,
  SET_SELECTED_DEVICE,
  TOGGLE_COLLAPSED_HARDWARE,
  TOGGLE_COLLAPSE_ALL_HARDWARE
} from './types'
import { REMOVE_USER_DATA } from '../types'

import { HardwareState } from './types'

import { encodeHardwareId } from '../../utils/encoding/index'
import { createEmptyFormattedHardware } from './functions'

export const initialState: HardwareState = {
  tagIdsIgnoredForSleepAndWakeAll: [],
  formattedHardware: createEmptyFormattedHardware(),
  diagnostics: {
    hardwareIndex: [],
    encodedHardwareIndex: [],
    countMatrix: [],
    rssiMatrix: [],
    imuLenMatrix: [],
    distanceMatrix: [],
    status: {
      online: {
        tags: [],
        balls: [],
        anchors: []
      },
      offline: {
        tags: [],
        balls: [],
        anchors: []
      },
      sleep: {
        tags: [],
        balls: [],
        anchors: []
      }
    },
    finalAnchorIndex: 0,
    masterSignalStrength: [],
    hardwareState: {},
    availableSeats: 0
  },
  selectedDevice: null,
  collapsedHardwareIds: {}
}

const generateEncodedHardwareIndex = ({ hardwareIndex }) => {
  return hardwareIndex.map((id) => encodeHardwareId(id))
}

export function hardwareReducer(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_IGNORE_TAG_FOR_SLEEP_AND_WAKE_ALL:
      if (state.tagIdsIgnoredForSleepAndWakeAll.includes(action.payload)) {
        return {
          ...state,
          tagIdsIgnoredForSleepAndWakeAll:
            state.tagIdsIgnoredForSleepAndWakeAll.filter(
              (id) => id !== action.payload
            )
        }
      } else {
        return {
          ...state,
          tagIdsIgnoredForSleepAndWakeAll: [
            ...state.tagIdsIgnoredForSleepAndWakeAll,
            action.payload
          ]
        }
      }

    case UPDATE_HARDWARE:
      let { diagnostics, formattedHardware } = action.payload
      let encodedHardwareIndex = generateEncodedHardwareIndex(diagnostics)

      return {
        ...state,
        diagnostics: {
          ...diagnostics,
          encodedHardwareIndex
        },
        formattedHardware
      }
    case SET_SELECTED_DEVICE:
      return {
        ...state,
        selectedDevice: action.payload
      }

    case TOGGLE_COLLAPSED_HARDWARE: {
      const encodedId = action.payload
      const isCurrentlyCollapsed = state.collapsedHardwareIds[encodedId]

      return {
        ...state,
        collapsedHardwareIds: {
          ...state.collapsedHardwareIds,
          [encodedId]: !isCurrentlyCollapsed
        }
      }
    }

    case TOGGLE_COLLAPSE_ALL_HARDWARE: {
      const newCollapsedHardwareIds = {}
      // collapse all
      if (action.payload) {
        state.diagnostics.hardwareIndex.forEach((hardwareId) => {
          newCollapsedHardwareIds[encodeHardwareId(hardwareId)] = false
        })
      } else {
        // expand all
        state.diagnostics.hardwareIndex.forEach((hardwareId) => {
          newCollapsedHardwareIds[encodeHardwareId(hardwareId)] = true
        })
      }

      return {
        ...state,
        collapsedHardwareIds: newCollapsedHardwareIds
      }
    }

    // Remove user data
    case REMOVE_USER_DATA:
      return initialState

    default:
      return state
  }
}
