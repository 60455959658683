import React, { useState } from 'react'
import { AuthFormConfig, AuthenticationForms } from '../form_config'

// Components
import { FormContainer } from '../../../components/Forms/Form/Form'

// Types
import { UserState } from '../../../metrics_server/user/types'
import { AuthenticationState } from '../../../ui/authentication/types'

export interface FormValidationErrors {
  [key: string]: string | undefined
}

const validate = (values) => {
  const errors: FormValidationErrors = {}
  if (!values.email) {
    errors.email = 'Required'
  }
  return errors
}

export interface VerifyEmailFormProps {
  requestEmailVerification: (data, loadingId) => void
  authentication: AuthenticationState
  user: UserState
}

export const VerifyEmailForm = (props: VerifyEmailFormProps) => {
  const { requestEmailVerification, user, authentication } = props

  const [fields, setFields] = useState(generateFields())

  function generateFields() {
    return []
  }

  const handleSubmit = () => {
    requestEmailVerification(
      { email: user.data.verificationEmail },
      AuthenticationForms.verifyEmail
    )
  }

  return (
    <>
      <FormContainer
        fields={fields}
        onSubmit={handleSubmit}
        submitText={'Resend'}
        loading={authentication.isLoading}
      >
        <div className='section'>
          <div className='section'>
            <div className='row'>
              <p>
                We've sent an email to {user.data.verificationEmail}, to
                complete the verification process please follow the link
                contained within the email.
              </p>
            </div>
          </div>
        </div>
      </FormContainer>
    </>
  )
}
