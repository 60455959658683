import React, { useEffect, useMemo, useRef, useState } from 'react'
import styles from './LineChart.module.scss'
import * as d3 from 'd3'
import { LineChartCoordinates } from '../../metrics_server/events/types'
import moment from '../../utils/moment'

export interface LineChartProps {
  impactData: {
    spinGraph: { X: number; Y: number }[]
    impactGraph: { X: number; Y: number }[]
    impactEvents: { X: number; Y: number }[]
    hitPost: boolean
  }
  startTime: number
  endTime: number
  coordinates: LineChartCoordinates[]
  timeCrossedLine: number
  width: number
  height: number
  marginTop: number
  marginLeft: number
  fontSize: number
}

export const LineChart = ({
  impactData,
  coordinates = [],
  startTime,
  endTime,
  timeCrossedLine,
  width,
  height,
  marginTop,
  marginLeft,
  fontSize
}: LineChartProps) => {
  const graphContainerRef = useRef(null)
  const svgRef = useRef(null)

  const [hoveredCoords, setHoveredCoords] = useState({ x: null, y: null })

  const allXCoordinates = useMemo(() => {
    // Get earliest and lastest x data points from coordinates and impactData
    const impactGraph = impactData.impactGraph || []

    return [
      ...coordinates.map((coord) => coord.time),
      ...impactGraph.map((coord) => coord.X)
    ]
  }, [coordinates, impactData])

  const [zoomedRange, setZoomedRange] = useState([
    d3.min(allXCoordinates),
    d3.max(allXCoordinates)
  ])
  const accelerationCoordinates = coordinates.map((coord) => coord.acceleration)
  // check if the max value is bigger than ten
  const accelerationLargerThanTen = accelerationCoordinates.some(
    (acc) => acc > 10
  )
  // check if coordinates have Gyro, if coordinates has "gyro" it's a flight event else it's a Goal or Behind
  const hasGyroCoords = coordinates.some((coord) => coord.gyro)

  const flightRawDataYScaleDomain = hasGyroCoords
    ? [0, 17]
    : accelerationLargerThanTen
    ? [0, Math.max(...accelerationCoordinates) * 1.05]
    : [0, 10]

  const formatXValue = (value) => {
    const date = moment.unix(value)
    const seconds = date.format('ss')
    return `${seconds}.${value.toString().split('.')[1] || 0}`
  }
  const handleZoomIn = () => {
    // Define the new visible range for zooming in leave some space around
    const newRange = [startTime - 0.5, endTime + 0.5]
    setZoomedRange(newRange)
  }

  const handleZoomOut = () => {
    // Reset zoomed range to show the entire data
    setZoomedRange([d3.min(allXCoordinates), d3.max(allXCoordinates)])
  }

  useEffect(() => {
    d3.select(svgRef.current).selectAll('*').remove()

    const xScale = d3
      .scaleLinear()
      .domain(zoomedRange)
      .range([marginLeft, width - marginLeft])

    const flightRawDataYScale = d3
      .scaleLinear()
      .domain(flightRawDataYScaleDomain)
      .range([height - marginTop, marginTop])

    const spinGraphYScale = d3
      .scaleLinear()
      .domain([0, d3.max(impactData.spinGraph.map((coord) => coord.Y))])
      .range([height - marginTop, marginTop])

    const impactGraphYScale = d3
      .scaleLinear()
      .domain([0, d3.max(impactData.spinGraph.map((coord) => coord.Y))])
      .range([height - marginTop, marginTop])

    const curve = d3.curveCardinal.tension(0.5) // Adjust the tension value as needed

    const flightRawDataLine = d3
      .line()
      .x((x) => xScale(x[0]))
      .y((y) => flightRawDataYScale(y[1]))
      .curve(curve)

    const spinGraphLine = d3
      .line()
      .x((x) => xScale(x[0]))
      .y((y) => spinGraphYScale(y[1]))
      .curve(curve)

    const impactGraphLine = d3
      .line()
      .x((x) => xScale(x[0]))
      .y((y) => impactGraphYScale(y[1]))
      .curve(curve)

    // get coordinations from time & amplitude and make new array to use in Line()
    const filteredCoordinates = coordinates.filter(
      (coord) => coord.time >= zoomedRange[0] && coord.time <= zoomedRange[1]
    )
    const filteredImpactCoords = impactData.impactGraph.filter(
      (coord) => coord.X >= zoomedRange[0] && coord.X <= zoomedRange[1]
    )
    const filteredSpinCoords = impactData.spinGraph.filter(
      (coord) => coord.X >= zoomedRange[0] && coord.X <= zoomedRange[1]
    )

    // acceleration Path
    d3.select(svgRef.current)
      .append('path')
      .attr('stroke', 'blue')
      .attr('stroke-width', 2)
      .attr('d', () =>
        flightRawDataLine(
          filteredCoordinates.map((coord) => [coord.time, coord.acceleration])
        )
      )
      .attr('fill', 'none')
      .style('cursor', 'pointer')

    //Gyro Path
    if (coordinates.some((coord) => coord.gyro)) {
      d3.select(svgRef.current)
        .append('path')
        .attr('stroke', 'orange')
        .attr('stroke-width', 2)
        .attr('d', () =>
          flightRawDataLine(
            filteredCoordinates.map((coord) => [coord.time, coord.gyro])
          )
        )
        .attr('fill', 'none')
        .style('cursor', 'pointer')
    }

    // Impact Path
    d3.select(svgRef.current)
      .append('path')
      .attr('stroke', 'red')
      .attr('stroke-width', 2)
      .attr('d', () =>
        impactGraphLine(filteredImpactCoords.map((coord) => [coord.X, coord.Y]))
      )
      .attr('fill', 'none')
      .style('cursor', 'pointer')

    // Spin Path
    d3.select(svgRef.current)
      .append('path')
      .attr('stroke', 'purple')
      .attr('stroke-width', 2)
      .attr(
        'd',
        spinGraphLine(filteredSpinCoords.map((coord) => [coord.X, coord.Y]))
      )
      .attr('fill', 'none')
      .style('cursor', 'pointer')

    const xAxis = d3.axisBottom(xScale).tickFormat(formatXValue)
    const yAxis = d3.axisLeft(flightRawDataYScale)

    // create xAxis
    d3.select(svgRef.current)
      .append('g')
      .attr('transform', `translate(0, ${height - marginTop})`)
      .attr('id', 'xAxis')
      .style('font-size', `${fontSize}`)
      .call(xAxis)

    // create yAxis
    d3.select(svgRef.current)
      .append('g')
      .attr('transform', `translate(${marginLeft}, 0)`)
      .attr('id', 'yAxis')
      .style('font-size', `${fontSize}`)
      .call(yAxis)

    // add xAxis Label
    d3.select(svgRef.current)
      .append('text')
      .attr('x', width / 2)
      .attr('y', height + 10)
      .attr('text-anchor', 'middle')
      .style('font-size', `${fontSize}`)
      .text(`Start time: ${moment(startTime * 1000).format('HH:mm:ss')}`)

    // Append StartTime
    if (startTime) {
      d3.select(svgRef.current)
        .append('g')
        .attr('transform', `translate(${xScale(startTime)},0)`)
        .append('line')
        .attr('stroke', 'red')
        .attr('y1', `${marginTop}`)
        .attr('y2', `${height - marginTop}`)
        .attr('stroke-width', 2.5)
    }

    // Append EndTime
    if (endTime) {
      d3.select(svgRef.current)
        .append('g')
        .attr('transform', `translate(${xScale(endTime)},0)`)
        .append('line')
        .attr('stroke', 'red')
        .attr('y1', `${marginTop}`)
        .attr('y2', `${height - marginTop}`)
        .attr('stroke-width', 2.5)
    }

    // Append inPitchHangTime
    if (timeCrossedLine && typeof timeCrossedLine === 'number') {
      const goalLineTimestamp = startTime + timeCrossedLine

      d3.select(svgRef.current)
        .append('g')
        .attr('transform', `translate(${xScale(goalLineTimestamp)},0)`)
        .append('line')
        .attr('stroke', 'green')
        .attr('y1', `${marginTop}`)
        .attr('y2', `${height - marginTop}`)
    }

    for (let i = 0; i < impactData.impactEvents.length; i++) {
      const impactEvent = impactData.impactEvents[i]
      d3.select(svgRef.current)
        .append('circle')
        .attr('cx', (d) => xScale(impactEvent))
        .attr('cy', (d) => flightRawDataYScale(1)) // Fixed Y value for ImpactEvents
        .attr('r', 4.5) // Set the radius of the dots
        .attr('fill', 'black') // Set the fill color of the dots
        .style('cursor', 'pointer')
    }
    // hover over path to see stats
    d3.select(svgRef.current)
      .selectAll('path')
      .on('mousemove', (event, d) => {
        const [x, y] = d3.pointer(event)
        const xValue = xScale.invert(x)
        const yValue = flightRawDataYScale.invert(y)

        const formattedX = parseFloat(formatXValue(xValue)).toFixed(2)
        const formattedY = yValue.toFixed(2)

        setHoveredCoords({
          x: { pos: x, value: formattedX },
          y: { pos: y, value: formattedY }
        })
      })
      .on('mouseout', () => {
        setHoveredCoords({ x: null, y: null })
      })

    return () => {
      // Clean up when the component unmounts
      d3.select(svgRef.current).selectAll('*').remove()
    }
  }, [coordinates, zoomedRange])

  return (
    <div className={styles.svgCont} ref={graphContainerRef}>
      <div className={styles.graphBtnsContainer}>
        <button onClick={handleZoomIn} className={styles.graphZoomBtn}>
          Zoom In
        </button>
        <button onClick={handleZoomOut} className={styles.graphZoomBtn}>
          Zoom Out
        </button>
      </div>
      <svg id='chartSvg' ref={svgRef} viewBox={`0 0 ${width} ${height + 20}`}>
        <path fill='none' stroke='currentColor' />
      </svg>
      {hoveredCoords.x !== null && (
        <div
          className={styles.tooltip}
          style={{
            position: 'absolute',
            left: `${hoveredCoords.x.pos}px`,
            top: `${hoveredCoords.y.pos}px`,
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '5px',
            borderRadius: '5px',
            boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)',
            zIndex: 999
          }}
        >
          {/* Customize the content of the tooltip */}
          X: {hoveredCoords.x.value}, Y: {hoveredCoords.y.value}
        </div>
      )}
    </div>
  )
}
