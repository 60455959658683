import React, { Component } from 'react'
import _ from 'lodash'
import { connect } from 'react-redux'
import { isLocal } from '../../metrics_server/env'
import { getShortcuts } from '../../constants/shortcuts'
import { SportscasterState } from '../../metrics_server/sportscaster/types'
import { StatusState } from '../../metrics_server/status/types'
import { HardwareState } from '../../metrics_server/hardware/types'
import { UnitsState } from '../../metrics_server/units/types'
import { UserState } from '../../metrics_server/user/types'
import { VersionState } from '../../metrics_server/version/types'
import { ToggleModal } from '../../ui/modal/types'
import { AppInformationToolTipIcon } from '../AppInformationToolTipIcon/AppInformationToolTipIcon'
import { HardwareGrid } from '../HardwareGrid/HardwareGrid'
import { MSDBSyncStatus } from '../MSDBSyncStatus/MSDBSyncStatus'
import { Options } from '../Options/Options'
import { SessionShortcutsToolTip } from '../SessionShortcutsToolTip/SessionShortcutsToolTip'
import { SportscasterTable } from '../SportscasterTable/SportscasterTable'
import styles from './StatusBar.module.scss'
import Loader from '../Loader/Loader'

import * as sessionActions from '../../metrics_server/sessions/actions'
import * as unitActions from '../../metrics_server/units/actions'
import * as versionActions from '../../metrics_server/version/actions'
import * as broadcastingActions from '../../metrics_server/broadcasting/actions'

import * as ui from '../../ui'
import { NetworkSettings } from '../NetworkSettings/NetworkSettings'
import { NotificationsState } from '../../metrics_server/notifications/types'
import { NotificationsList } from '../Notifications/NotificationsList/NotificationsList'
import { isProduction } from '../../metrics_server/version/function'

import infoImg from '../../assets/img/info.svg'

import memoize from 'memoize-one'
import { sportableColors } from '../../constants/sportableColors'
import { MIcon } from '../Icons/MIcon/MIcon'
import { useOpenUnitSelectModal } from '../../metrics_server/units/hooks'
import { MSErrors } from '../MSErrors/MSErrors'
import { isTraining } from '../../metrics_server/sessions/functions'
import {
  DeviceType,
  hardwareFullLoadCount,
  deviceTypes
} from '../../metrics_server/hardware/data_types'
import { diagnosticsPath } from '../../views/Diagnostics/config'
import { isSentrySetup, openSentryBugReporting } from '../../sentry'
import { getAppTypeCheck } from '../../metrics_server/user/functions'
import { BroadcastingState } from '../../metrics_server/broadcasting/types'
import { SessionsState } from '../../metrics_server/sessions/types'

let actions = _.assign(
  {},
  sessionActions,
  versionActions,
  unitActions,
  broadcastingActions
)
for (const key in ui.actions) {
  actions = { ...actions, ...ui.actions[key] }
}

const MultiState = (props) => {
  let active
  props.active ? (active = 'on') : (active = 'off')
  return (
    <div className={styles.statusContainer}>
      <div
        style={{ background: props.color || 'transparent' }}
        className={`${styles.light} ${styles[active]}`}
      ></div>
      <div
        onClick={() => {
          if (props.handleClick) props.handleClick()
        }}
        className={styles.statusName}
      >
        <h5 className={styles.statusText}>
          {props.name}: {props.number}
        </h5>
        {!props.hideInfoImg && <img src={infoImg} alt='' />}
      </div>
    </div>
  )
}

export interface StatusBarProps {
  sportscaster: SportscasterState
  status: StatusState
  user: UserState
  version: VersionState
  notifications: NotificationsState
  units: UnitsState
  hardware: HardwareState
  broadcasting: BroadcastingState
  setRedirect: (route) => any
  toggleModal: ToggleModal
  toggleNotifications: (notifications) => any
  setUnitSystem: (unitSystem) => any
  clearNotificationsHistory: () => any
  clearNotificationInHistory: (notification) => any
  toggleAutoBroadcasting: (broadcasting) => any
  setInfo: (info) => any
  toggleKeyboardShortcuts: (bool: boolean) => { type: string; payload: boolean }
  openUnitSelect: () => void
  sessions: SessionsState
}

interface StatusBarState {
  redirect: string | null
  numberOfTags: string | number
  sportscasterHealth: string
  userData: any
}

class StatusBar extends Component<StatusBarProps, StatusBarState> {
  constructor(props) {
    super(props)

    this.state = {
      userData: {},
      redirect: null,
      numberOfTags: '...',
      sportscasterHealth: sportableColors.colors.colorError
    }

    this.generateCogOptions = this.generateCogOptions.bind(this)
    this.openNetworkSettings = this.openNetworkSettings.bind(this)
  }

  componentDidUpdate(prevProps, prevState) {
    // Sportscaster Health
    const { sportscaster } = this.props

    // console.log(sportscaster.health)
    if (sportscaster.status !== prevProps.sportscaster.status) {
      const previousHealth = prevState.sportscasterHealth
      const health = this.getSportscasterHealth(sportscaster.status)
      if (previousHealth !== health) {
        this.setState({
          sportscasterHealth: this.getSportscasterHealth(sportscaster.status)
        })
      }
    }

    // if (status.dbsync !== prevProps.status.dbsync) {
    //   console.log(status)
    // }
  }

  getSportscasterHealth(sportscasterStatus) {
    const healthySportscasters = sportscasterStatus.filter((x) => {
      return x.status
    })

    if (healthySportscasters.length === 0) {
      return sportableColors.colors.colorError
    } else if (healthySportscasters.length === sportscasterStatus.length) {
      return sportableColors.colors.colorSuccess
    } else {
      return sportableColors.colors.colorWarning
    }
  }

  openNetworkSettings() {
    this.props.toggleModal({
      active: true,
      type: 'form',
      message: 'Network settings',
      ChildComponent: NetworkSettings,
      className: 'modalLarge',
      handleClose: () => {
        this.props.toggleModal({})
      }
    })
  }

  toggle3D = () => {
    const threeD = localStorage.getItem('3D')
    const isEnabled = threeD === 'enabled'
    this.props.setInfo({
      header: '',
      message: `This will require the app to reload. Are you sure you want to proceed?`,
      proceed: () => {
        localStorage.setItem('3D', isEnabled ? 'disabled' : 'enabled')
        window.location.reload()
      }
    })
  }

  generateCogOptions() {
    const {
      user,
      notifications,
      units,
      broadcasting,
      toggleKeyboardShortcuts
    } = this.props
    const { isAdmin } = getAppTypeCheck(user)

    const options = [
      {
        name: `Unit select - ${units.selected}`,
        handle: this.props.openUnitSelect
      },
      {
        name: 'Network Settings',
        handle: this.openNetworkSettings
      },
      {
        name:
          localStorage.getItem('3D') === 'enabled' ? 'Disable 3D' : 'Enable 3D',
        handle: this.toggle3D
      }
    ]

    if (isAdmin) {
      options.push({
        name: broadcasting.autoBroadcastEnabled
          ? 'Disable broadcasting'
          : 'Enable broadcasting',
        handle: this.openBroadcastingConfirmationModal
      })
    }

    if (isSentrySetup()) {
      options.push({
        name: 'Report a bug 🐛',
        handle: () => {
          openSentryBugReporting(toggleKeyboardShortcuts)
        }
      })
    }
    if (user.data && user.data.id) {
      options.push({
        name: notifications.on
          ? `Turn off notifications`
          : `Turn on notifications`,
        handle: () => this.props.toggleNotifications(!notifications.on)
      })
    }
    if (isAdmin)
      options.push({
        name: `Open Diagnostics`,
        handle: () => this.props.setRedirect(diagnosticsPath)
      })
    return options
  }

  openBatteryModal = (deviceType: DeviceType) => {
    this.props.toggleModal({
      active: true,
      type: 'confirm',
      handleProceed: () => {
        this.props.toggleModal({})
      },
      title: `${deviceType.name}s`,
      ChildComponent: () => (
        <HardwareGrid
          deviceTypeValue={deviceType.value}
          hardwareStates={['online', 'sleep']}
        />
      ),
      wrapper: true,
      className: 'modalSmall',
      width: '50%',
      handleSecondaryBtn: () => {
        this.props.toggleModal({})
      }
    })
  }

  openSportscasterModal = () => {
    const { sportscaster } = this.props
    const title = `Brokers: ${sportscaster.locationName}`

    this.props.toggleModal({
      active: true,
      type: 'confirm',
      handleProceed: () => {
        this.props.toggleModal({})
      },

      ChildComponent: () => {
        return <SportscasterTable type={'selectedBrokerHost'} />
      },
      wrapper: true,
      title,

      // modal props

      className: 'modalSmall',
      width: '50%',
      handleSecondaryBtn: () => {
        this.props.toggleModal({})
      }
    })
  }

  openBroadcastingConfirmationModal = () => {
    const { broadcasting } = this.props

    const handleSubmit = () => {
      this.props.toggleAutoBroadcasting(!broadcasting.autoBroadcastEnabled)
      this.props.toggleModal({})
    }

    this.props.setInfo({
      header: 'MQTT Broadcasting',
      message: `Are you sure you want to ${
        broadcasting.autoBroadcastEnabled ? 'disable' : 'enable'
      } automated mqtt broker data?`,
      proceed: () => handleSubmit()
    })
  }

  // Re-run the filter whenever the notifications history array changes:
  filterNotifications = memoize((notifications) => {
    return notifications.allNotificationsHistory.filter(
      (item, index) => index < 25
    )
  })

  render() {
    const { ui, server, uiType, app, deamon } = this.props.version
    const {
      user,
      hardware,
      version,
      notifications,
      sportscaster,
      broadcasting,
      sessions
    } = this.props

    const { sportscasterHealth } = this.state

    const isAppEnvProduction = isProduction(version)

    const filteredHistoricalNotifications =
      this.filterNotifications(notifications)

    const filteredNotifications = {
      ...notifications,
      allNotificationsHistory: filteredHistoricalNotifications
    }

    const numberOfAlerts = notifications.allNotificationsHistory.length

    const isTrainingMode = isTraining(sessions.activeSession?.type)

    const appTypeCheck = getAppTypeCheck(user)
    const { isAdmin, isMatchTracker } = appTypeCheck

    const seatsAvailable =
      hardwareFullLoadCount - hardware.formattedHardware.devices.count

    return (
      <div className={styles.statusBarContainer}>
        <div className={styles.tooltips}>
          <AppInformationToolTipIcon
            uiType={uiType}
            isProduction={isAppEnvProduction}
            app={app}
            ui={ui}
            server={server}
            deamon={deamon}
          />
          <SessionShortcutsToolTip
            shortcuts={getShortcuts(appTypeCheck)}
            sessions={this.props.sessions}
          />
        </div>
        <div className={styles.statusBar}>
          <div>
            {isLocal && (
              <React.Fragment>
                {(isAdmin || isMatchTracker) && (
                  <>
                    <MSErrors />
                    <MSDBSyncStatus color={'white'} />
                    <MultiState
                      name='Brokers'
                      active={!!this.state.userData}
                      number={sportscaster.status.length}
                      color={sportscasterHealth}
                      handleClick={() => this.openSportscasterModal()}
                    />
                  </>
                )}
                {(isAdmin || isMatchTracker) && !isTrainingMode && (
                  <div
                    onClick={() => {
                      if (broadcasting.autoBroadcastEnabled !== null) {
                        this.openBroadcastingConfirmationModal()
                      }
                    }}
                    style={{ display: 'flex' }}
                  >
                    {broadcasting.autoBroadcastEnabled !== null ? (
                      <MIcon
                        scale={0.8}
                        color={
                          broadcasting.autoBroadcastEnabled
                            ? 'white'
                            : sportableColors.colors.colorError
                        }
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          height: '20px',
                          width: '20px'
                        }}
                      >
                        <Loader color={'white'} />
                      </div>
                    )}
                  </div>
                )}
                <MultiState
                  name='Anchors'
                  active={!!this.state.userData}
                  number={hardware.diagnostics.status.online.anchors.length}
                  handleClick={() =>
                    this.openBatteryModal(deviceTypes.items.anchor)
                  }
                />
                <MultiState
                  name='Tags'
                  active={!!this.state.userData}
                  number={
                    <>
                      <span className={styles.onlineNumber}>
                        {hardware.diagnostics.status.online.tags.length}
                      </span>
                      ({hardware.diagnostics.status.sleep.tags.length})
                    </>
                  }
                  handleClick={() =>
                    this.openBatteryModal(deviceTypes.items.playerTag)
                  }
                />
                <MultiState
                  name='Balls'
                  active={!!this.state.userData}
                  number={
                    <>
                      <span className={styles.onlineNumber}>
                        {hardware.diagnostics.status.online.balls.length}
                      </span>
                      ({hardware.diagnostics.status.sleep.balls.length})
                    </>
                  }
                  handleClick={() =>
                    this.openBatteryModal(deviceTypes.items.ball)
                  }
                />
                <MultiState
                  name='Seats Available'
                  hideInfoImg={true}
                  number={hardware.diagnostics.availableSeats}
                  color={
                    hardware.diagnostics.availableSeats < 0
                      ? sportableColors.colors.colorError
                      : undefined
                  }
                />
              </React.Fragment>
            )}
          </div>

          <div className={styles.activeIndicators}>
            <div className={styles.flexRow}>
              <NotificationsList
                notifications={filteredNotifications}
                clearAllNotifications={() =>
                  this.props.clearNotificationsHistory()
                }
                clearSelectedNotification={
                  this.props.clearNotificationInHistory
                }
                numberOfAlerts={numberOfAlerts}
              />
            </div>
          </div>

          <div className={styles.activeIndicators}>
            <div className={styles.flexRow}>
              <Options type='settings' options={this.generateCogOptions()} />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps(state) {
  return {
    play: state.play,
    activeSession: state.activeSession,
    version: state.version,
    hardware: state.hardware,
    user: state.user,
    notifications: state.notifications,
    units: state.units,
    sportscaster: state.sportscaster,
    status: state.status,
    broadcasting: state.broadcasting
  }
}

export function withOpenUnitSelectModal(WrappedComponent) {
  return function (props) {
    const openUnitSelect = useOpenUnitSelectModal()

    return <WrappedComponent {...props} openUnitSelect={openUnitSelect} />
  }
}

export default connect<any, any, any>(
  mapStateToProps,
  actions
)(withOpenUnitSelectModal(StatusBar))
