import _ from 'lodash'
import { getColor, timestampToFomattedDate } from '../../utils/helpers'
import {
  FormattedPlayer,
  PlayerData,
  PlayersState,
  RawPlayerData
} from '../players/types'
import {
  AppTeam,
  FormattedTeam,
  RawTeamData,
  Team,
  TeamsState
} from '../teams/types'
import {
  SessionsState,
  SessionPlayers,
  SessionTeams,
  SessionData,
  SessionSetup,
  FormattedSessionPlayers,
  FormattedSession,
  RawSessionData,
  RawTeamSessionData,
  FormattedPlayerSession,
  RawPlayerSessionData,
  FormattedTeamSession
} from './types'
import { SessionTypeValues, sessionTypes } from './data_types'
import { OrganisationsState } from '../organisations/types'
import { formatTeam, formatTeams, getTeamById } from '../teams/functions'
import { getSport } from '../sports/functions'
import { sessionModeTypes } from './modes/data_types'
import { Group, IDMap, addItemToGroup } from '../types'
import { subSessionTypes } from './sub_sessions/data_types'
import { sessionStateTypes } from './states/data_types'
import { Anchor, Pitch } from '../pitches/types'
import { FormattedHardware } from '../hardware/types'
import { SessionBroadcastIntergrationCompletion } from '../broadcast_integration/functions'
import { getStrackPitchAndAnchorConfig } from '../../utils/strack/functions'
import { RawBroadcastingState } from '../broadcasting/types'
import {
  GameEventTypeGroup,
  GameEventTypeKeys,
  GameEventTypes,
  getGameEventTypeGroup
} from '../events/game/data_types'
import moment from 'moment'
import { sportTypeKey } from '../sports'
import { AppTypeCheck } from '../user/types'

export function formatTeamSession(
  teamSession: RawTeamSessionData,
  team: FormattedTeam,
  oppositionTeamId?: string
): FormattedTeamSession {
  return {
    id: teamSession.id,
    teamId: teamSession.teamId,
    sessionId: teamSession.sessionId,
    homeAway: teamSession.homeAway,
    team,
    rawData: teamSession,
    oppositionTeamId
  }
}

export function formatTeamSessions(
  teamsSessions: RawTeamSessionData[],
  formattedTeams: Group<FormattedTeam>
): Group<FormattedTeamSession> {
  const formattedTeamSessions = getEmptyGroup<FormattedTeamSession>()
  teamsSessions.forEach((teamSession) => {
    const team = formattedTeams.map[teamSession.teamId]
    const oppositionTeamSession = teamsSessions.find((ts) => {
      return ts.teamId !== teamSession.teamId
    })
    const fomattedTeamSession = formatTeamSession(
      teamSession,
      team,
      oppositionTeamSession?.teamId
    )
    addItemToGroup(
      formattedTeamSessions,
      fomattedTeamSession,
      teamSession.teamId,
      'id',
      team.name
    )
  })
  return formattedTeamSessions
}

export function getEmptyGroup<T, ov = string>(): Group<T, ov> {
  return {
    list: [],
    map: {},
    options: [],
    optionsWithAll: [{ name: 'All', value: 'All' }],
    optionsWithNull: [{ name: 'Select', value: null }],
    count: 0
  }
}

export function formatPlayerSession(
  playerSession: RawPlayerSessionData,
  player: FormattedPlayer
): FormattedPlayerSession {
  // Overwrite player number with session number
  if (player) player.number = playerSession.number
  return {
    id: playerSession.id,
    number: playerSession.number,
    playerId: playerSession.playerId,
    teamId: playerSession.teamId,
    sessionId: playerSession.sessionId,
    hardwareId: playerSession.tag?.id,
    hardware: playerSession.tag,
    player,
    device: null
  }
}

function getPlayerSessionOptionName(item: FormattedPlayerSession) {
  return `${item.player.firstName} ${item.player.lastName} - ${item.number}`
}

export function formatPlayerSessions(
  playersSessions: RawPlayerSessionData[],
  teams: Group<FormattedTeam>,
  officiationTeam: FormattedTeam
): {
  byId: Group<FormattedPlayerSession>
  byHardwareId: Group<FormattedPlayerSession>
  byPlayerId: Group<FormattedPlayerSession>
  byNumber: { [teamId: string]: Group<FormattedPlayerSession> }
  balls: Group<FormattedPlayerSession>
} {
  const byId = getEmptyGroup<FormattedPlayerSession>(),
    byHardwareId = getEmptyGroup<FormattedPlayerSession>(),
    byPlayerId = getEmptyGroup<FormattedPlayerSession>(),
    byNumber = {} as {
      [teamId: string]: Group<FormattedPlayerSession>
    },
    balls = getEmptyGroup<FormattedPlayerSession>()

  // Format player session and store as maps using playerId, hardwareId and number as keys
  playersSessions.forEach((playerSession) => {
    let team = teams.map[playerSession.teamId]

    // Check if it's a official (no team found in teamsSessions but has a teamId)
    if (!team && playerSession.teamId) team = officiationTeam

    const formattedPlayerSession = formatPlayerSession(
      playerSession,
      team?.players.map[playerSession.playerId]
    )

    addItemToGroup(
      byId,
      formattedPlayerSession,
      playerSession.id,
      'id',
      playerSession.id
    )
    if (formattedPlayerSession.hardwareId) {
      addItemToGroup(
        byHardwareId,
        formattedPlayerSession,
        formattedPlayerSession.hardwareId,
        'id',
        formattedPlayerSession.id
      )
    }
    if (formattedPlayerSession.player) {
      addItemToGroup(
        byPlayerId,
        formattedPlayerSession,
        formattedPlayerSession.playerId,
        'id',
        formattedPlayerSession.id
      )
    }
  })

  // Add player sessions to teams by number //
  teams.list.forEach((team) => {
    const players = team.players
    byNumber[team.id] = getEmptyGroup<FormattedPlayerSession>()
    playersSessions.forEach((playerSession) => {
      const player = players.map[playerSession.playerId]
      if (!player) return
      const formattedPlayerSession = formatPlayerSession(playerSession, player)
      if (formattedPlayerSession.teamId === team.id) {
        addItemToGroup(
          byNumber[team.id],
          formattedPlayerSession,
          formattedPlayerSession.number,
          'id',
          formattedPlayerSession.id
        )
      }
    })
  })

  // Add player sessions to officiation team by number //
  if (officiationTeam) {
    const players = officiationTeam.players
    byNumber[officiationTeam.id] = getEmptyGroup<FormattedPlayerSession>()
    playersSessions.forEach((playerSession) => {
      const player = players.map[playerSession.playerId]
      if (!player) return
      const formattedPlayerSession = formatPlayerSession(playerSession, player)
      if (formattedPlayerSession.teamId === officiationTeam.id) {
        addItemToGroup(
          byNumber[officiationTeam.id],
          formattedPlayerSession,
          formattedPlayerSession.number,
          'id',
          formattedPlayerSession.id
        )
      }
    })
  }

  // Balls //
  playersSessions.forEach((playerSession) => {
    if (playerSession.tag && playerSession.tag.id && !playerSession.playerId) {
      const formattedPlayerSession = formatPlayerSession(playerSession, null)
      addItemToGroup(
        balls,
        formattedPlayerSession,
        formattedPlayerSession.hardwareId,
        'id',
        formattedPlayerSession.id
      )
    }
  })

  return {
    byId,
    byHardwareId,
    byPlayerId,
    byNumber,
    balls
  }
}

export const filterGameEventsForOfficiation = (
  gameEventTypes: GameEventTypeGroup,
  isOfficiatingMode: boolean
): GameEventTypeGroup => {
  const gameEventTypesConfig: Partial<GameEventTypes> = {}

  for (const key in gameEventTypes.items) {
    const gameEventType = gameEventTypes.items[key as GameEventTypeKeys]
    if (
      gameEventType.props.features.officiatingModeOnly &&
      !isOfficiatingMode
    ) {
      continue
    }
    gameEventTypesConfig[key] = gameEventType
  }

  return getGameEventTypeGroup(gameEventTypesConfig)
}

export function formatSessionData(
  session: RawSessionData,
  rawTeams: IDMap<RawTeamData>,
  rawPlayers: IDMap<RawPlayerData>,
  live: boolean,
  pitchInUse: Pitch,
  rawOfficiationTeam: RawTeamData,
  isKeyboardShortcutEnabled?: boolean
): FormattedSession {
  if (!session.playersSessions) session.playersSessions = []
  if (!session.teamsSessions) {
    console.log('Session has no teamsSessions')
    return null
  }

  const teamsArray = session.teamsSessions.map(
    (teamsSession) => rawTeams[teamsSession.teamId] || teamsSession.team
  )

  const playersArray = session.playersSessions
    .filter((playerSession) => playerSession.player)
    .map(
      (playerSession) =>
        rawPlayers[playerSession.playerId] || {
          ...playerSession.player,
          teamId: playerSession.teamId
        }
    )

  const teams = formatTeams(teamsArray, playersArray)

  const officiationTeam = rawOfficiationTeam
    ? formatTeam(rawOfficiationTeam, playersArray)
    : null

  // Get sport from session sportType //
  const sport = getSport(session.sportType)
  // ================================= //
  const isTrainingMode = isTraining(session.type)
  const isMatchMode = isMatch(session.type)

  // Teams and Players //
  const teamsSessions = formatTeamSessions(session.teamsSessions, teams)
  const playersSessions = formatPlayerSessions(
    session.playersSessions,
    teams,
    officiationTeam
  )
  const players = {
    all: getEmptyGroup<FormattedPlayer>()
  }
  teams.list.forEach((team) => {
    players[team.id] = getEmptyGroup<FormattedPlayer>()
    team.players.list.forEach((player) => {
      const playerSession = playersSessions.byPlayerId.map[player.id]
      if (!playerSession) return
      const optionName = getPlayerSessionOptionName(playerSession)
      player.nameAndNumber = optionName
      // Overwrite player number with session number
      player.number = playerSession.number
      addItemToGroup(players.all, player, player.id, 'id', optionName)
      addItemToGroup(players[team.id], player, player.id, 'id', optionName)
    })
  })

  // Add officiation players - this is a bit hacky //
  if (officiationTeam) {
    players[officiationTeam.id] = getEmptyGroup<FormattedPlayer>()
    officiationTeam.players.list.forEach((player) => {
      const playerSession = playersSessions.byPlayerId.map[player.id]
      const optionName = getPlayerSessionOptionName(playerSession)
      player.nameAndNumber = optionName
      // Overwrite player number with session number
      player.number = playerSession.number
      addItemToGroup(
        players[officiationTeam.id],
        player,
        player.id,
        'id',
        optionName
      )
    })
  }

  // ================================= //

  // Get home and away team ids //
  let homeTeamId, awayTeamId
  teamsSessions.list.forEach((teamSession, index) => {
    if (teamSession.homeAway === 'HOME') {
      homeTeamId = teamSession.teamId
    } else if (teamSession.homeAway === 'AWAY') {
      awayTeamId = teamSession.teamId
    } else {
      if (index === 0) {
        homeTeamId = teamSession.teamId
      } else if (index === 1) {
        awayTeamId = teamSession.teamId
      }
    }
  })
  // ================================= //

  // Event Types //
  const eventTypes = sport.props.eventTypes
  // Flight //
  const flightTypes = sport.props.eventTypes.items.flight.props.types
  const flightMetrics = sport.props.eventTypes.items.flight.props.metricTypes
  // Game //
  const gameEventTypes = filterGameEventsForOfficiation(
    sport.props.eventTypes.items.game.props.types,
    session.officiatingAlgosEnable
  )
  const gameEventMetrics = sport.props.eventTypes.items.game.props.metricTypes
  // Time //
  const timeEventTypes = sport.props.eventTypes.items.time.props.types
  // Australian Rules //
  const australianRulesEventTypes =
    sport.props.eventTypes.items.aussieRules?.props.types
  const australianRulesEventMetrics =
    sport.props.eventTypes.items.aussieRules?.props.metricTypes
  // ================================= //

  // Player Metrics //
  const playerSummaryMetricTypes = sport.props.playerSummaryMetricTypes

  // Pitch and anchors //
  const { pitch, anchorConfig } = getStrackPitchAndAnchorConfig(
    session.pitch || pitchInUse,
    session.sportType
  )
  const anchors = getEmptyGroup<Anchor>()
  for (const anchorId in anchorConfig) {
    const anchor = anchorConfig[anchorId]
    addItemToGroup(anchors, anchor, anchor.id, 'id', anchor.serial)
  }
  // ================================= //

  // Status //
  const isSessionUploaded =
    session.uploadStatus?.dump || session.uploadStatus?.daemon
  // ================================= //

  return {
    sessionData: session,

    isSetup: !session.id,

    id: session.id || 'setup',
    name: session.name,
    startTime: timestampToFomattedDate(session.startTime),
    endTime: timestampToFomattedDate(session.endTime),
    sport,
    type: sessionTypes.getTypeByValue(session.type),
    subType: subSessionTypes.getTypeByValue(session.subType),
    mode: sessionModeTypes.getTypeByValue(session.mode),
    state: sessionStateTypes.getTypeByValue(session.state),
    isTrainingMode,
    isMatchMode,
    live,
    hostname: session.hostname,
    locationName: session.locationName,
    uploadStatus: session.uploadStatus,
    isSessionUploaded,
    isOfficiatingMode: session.officiatingAlgosEnable,
    isKeyboardShortcutEnabled: isKeyboardShortcutEnabled,

    // Teams and Players
    teams,
    teamsSessions,
    players: players,
    playersSessions,
    homeTeam: teams.map[homeTeamId],
    awayTeam: teams.map[awayTeamId],
    officiationTeam,

    // Player Metrics
    playerSummaryMetricTypes,

    // Balls
    balls: playersSessions.balls,

    // Event types and metrics
    eventTypes,
    flightTypes,
    flightMetrics,
    timeEventTypes,
    gameEventTypes,
    gameEventMetrics,
    australianRulesEventTypes,
    australianRulesEventMetrics,

    // Pitch and anchors
    pitchId: session.pitchId,
    pitch,
    anchorConfig,
    anchors,

    // Features
    homeSideCheck: sport.props.features.homeSideCheck && isMatchMode

    // officiation
  }
}

// format session data - turn enums into data type classes
export function formatSessionSetupData(session: SessionSetup) {
  const isTrainingMode = isTraining(session.type)

  // Get sport from session sportType
  const sport = getSport(session.sportType)

  // Get session type
  const sessionType = sessionTypes.getTypeByValue(session.type)
  const subSessionType = sessionType.props.subTypes.getTypeByValue(
    session.subType
  )
  const sessionModeType = sessionModeTypes.getTypeByValue(session.mode)

  // Create team options and teams map
  const teamMap: IDMap<Team> = _.keyBy(
    session.teamsSessions.map((teamSession) => teamSession.team),
    'id'
  )

  const teamOptions = session.teamsSessions.map((x) => {
    return {
      name: x.team.name,
      value: x.team.id
    }
  })

  // Filter players from balls
  const sessionPlayersArray = session.playersSessions.filter(
    (playerSession) => {
      if (playerSession.player && playerSession.player.id) {
        return playerSession.player
      }
    }
  )

  // Create player sessions object and array for select inputs
  const sessionPlayers: SessionPlayers = _.keyBy(
    sessionPlayersArray,
    'playerId'
  )
  const playerOptions = sessionPlayersArray.map((x) => {
    return {
      name: `${x.player.firstName} ${x.player.lastName} - ${x.number}`,
      value: x.playerId,
      number: x.number
    }
  })

  const playerMap: IDMap<PlayerData> = _.keyBy(
    sessionPlayersArray.map((playerSession) => ({
      ...playerSession.player,
      teamId: playerSession.teamId
    })),
    'id'
  )

  // formatted session players
  const formattedSessionPlayers: FormattedSessionPlayers = {
    options: playerOptions,
    map: {}
  }

  sessionPlayersArray.forEach((playerSession) => {
    formattedSessionPlayers.map[playerSession.playerId] = {
      playerData: playerMap[playerSession.playerId],
      playerSession
    }
  })

  // New team map
  const newTeamMap: IDMap<AppTeam> = {}

  function getOppositionTeamId(teamId: string) {
    const oppositionTeam = session.teamsSessions.find((teamSession) => {
      return teamSession.teamId !== teamId
    })
    return oppositionTeam?.teamId
  }

  session.teamsSessions.forEach((teamSession) => {
    const teamPlayersArray = sessionPlayersArray.filter(
      (player) => player.teamId === teamSession.teamId
    )
    const playerList = teamPlayersArray.map((playerSession) => ({
      ...playerSession.player,
      teamId: playerSession.teamId
    }))
    const playerOptions = teamPlayersArray.map((x) => {
      return {
        name: `${x.number}. ${x.player.firstName} ${x.player.lastName}`,
        value: x.playerId,
        number: x.number
      }
    })
    newTeamMap[teamSession.teamId] = {
      data: teamSession.team,
      players: {
        // list: playerList,
        map: _.keyBy(playerList, 'id'),
        options: playerOptions
      },
      oppositionTeamId: getOppositionTeamId(teamSession.teamId)
    }
  })

  const newPlayerMap: IDMap<AppTeam> = {}

  return {
    session,
    isTrainingMode,
    sport,
    sessionModeType,
    sessionType,
    subSessionType,
    teamOptions,
    sessionPlayers,
    playerOptions,
    playerMap,
    teamMap,
    newTeamMap,

    formattedSessionPlayers
  }
}

export function isMatch(sessionTypeValue: SessionTypeValues) {
  return sessionTypeValue === sessionTypes.items.match.value
}

export function isTraining(sessionTypeValue: SessionTypeValues) {
  return sessionTypeValue === sessionTypes.items.training.value
}

export function isActiveSessionOnDevice(
  sessionId: string,
  sessions: SessionsState
) {
  return sessions.activeSession?.id === sessionId
}

export function getSessionState(
  sessions: SessionsState,
  players: PlayersState,
  teams: TeamsState,
  organisations: OrganisationsState
) {
  const session = sessions.selected
  const team = session.teamsSessions[0].team
  const teamB = session.teamsSessions[1]?.team

  // Create team options
  const teamOptions = session.teamsSessions.map((x) => {
    return {
      name: x.team.name,
      value: x.team.id
    }
  })

  const sessionTeams = {} as SessionTeams
  session.teamsSessions.forEach((teamSession) => {
    const team = getTeamById(teamSession.teamId, teams, organisations)
    if (team) {
      sessionTeams[teamSession.teamId] = { ...teamSession }
      sessionTeams[teamSession.teamId].team.logo = team.logo
    }
  })

  // Create team options and teams map
  const teamMap: IDMap<Team> = _.keyBy(
    session.teamsSessions.map((teamSession) => teamSession.team),
    'id'
  )

  // Filter players from balls
  const sessionPlayersArray = session.playersSessions.filter(
    (playerSession) => {
      if (playerSession.player && playerSession.player.id) {
        return playerSession.player
      }
    }
  )

  // Create player sessions object and array for select inputs
  const sessionPlayers: SessionPlayers = _.keyBy(
    sessionPlayersArray,
    'playerId'
  )
  const playerOptions = sessionPlayersArray.map((x) => {
    return {
      name: `${x.player.firstName} ${x.player.lastName} - ${x.number}`,
      value: x.playerId,
      number: x.number
    }
  })

  const playerMap: IDMap<PlayerData> = _.keyBy(
    sessionPlayersArray.map((playerSession) => ({
      ...playerSession.player,
      teamId: playerSession.teamId
    })),
    'id'
  )

  // Add images and colours to players
  let playersSessions
  if (sessionPlayers) {
    let playerIndex = 0
    playersSessions = session.playersSessions.map(
      (playerSession: RawPlayerSessionData) => {
        const player = playerSession.player

        // Fetch image for player
        if (players) {
          const fullPlayer = players.items[playerSession.playerId]
          if (fullPlayer) player.img = fullPlayer.img
        }

        if (player) {
          player.color = getColor(playerIndex)
          playerIndex++
        }
        return playerSession
      }
    )
  }
  const formattedSessionPlayers: FormattedSessionPlayers = {
    options: playerOptions,
    map: {}
  }

  sessionPlayersArray.forEach((playerSession) => {
    formattedSessionPlayers.map[playerSession.playerId] = {
      playerData: playerMap[playerSession.playerId],
      playerSession
    }
  })
  // TODO: refactor needed here - trying not to break anything - team map should contain a map of formatted teams

  const newTeamMap: IDMap<AppTeam> = {}

  function getOppositionTeamId(teamId: string) {
    const oppositionTeam = session.teamsSessions.find((teamSession) => {
      return teamSession.teamId !== teamId
    })
    return oppositionTeam?.teamId
  }

  session.teamsSessions.forEach((teamSession) => {
    const teamPlayersArray = sessionPlayersArray.filter(
      (player) => player.teamId === teamSession.teamId
    )
    newTeamMap[teamSession.teamId] = {
      data: teamSession.team,
      players: {
        map: _.keyBy(
          teamPlayersArray.map((playerSession) => ({
            ...playerSession.player,
            teamId: playerSession.teamId
          })),
          'id'
        ),
        options: teamPlayersArray.map((x) => {
          return {
            name: `${x.number}. ${x.player.firstName} ${x.player.lastName}`,
            value: x.playerId,
            number: x.number
          }
        })
      },
      oppositionTeamId: getOppositionTeamId(teamSession.teamId)
    }
  })

  const { pitch, anchorConfig } = getStrackPitchAndAnchorConfig(
    session.pitch,
    session.sportType
  )

  return {
    team,
    teamB,
    session,
    playersSessions,
    pitch,
    anchorConfig,
    sessionPlayers,
    playerOptions,
    teamOptions,
    sessionTeams,
    playerMap,
    teamMap,
    newTeamMap,
    formattedSessionPlayers
  }
}

export const getHighlightedSide = (session: SessionData | RawSessionData) => {
  const { sessionControl } = session
  if (
    sessionControl &&
    sessionControl.sides &&
    sessionControl.sides.length >= 2 &&
    sessionControl.sides[0].id &&
    sessionControl.sides[0].id !== '0'
  ) {
    if (sessionControl.possession.id === sessionControl.sides[0].id) {
      return 0
    } else if (sessionControl.possession.id === sessionControl.sides[1].id) {
      return 1
    }
  }
  return null
}

export const getSessionTeamsArray = (
  session: SessionData,
  organisations: OrganisationsState,
  teams: TeamsState
) => {
  const { sessionControl } = session
  if (
    sessionControl &&
    sessionControl.sides &&
    sessionControl.sides[0].id &&
    sessionControl.sides[0].id !== '0'
  ) {
    return sessionControl.sides.map((side) => {
      const team = getTeamById(side.id, teams, organisations)
      return team
    })
  } else {
    return session.teamsSessions.map((ts) => {
      const team = getTeamById(ts.teamId, teams, organisations)
      return team
    })
  }
}

export const getSessionTeamSides = (
  broadcastState: RawBroadcastingState,
  formattedSession: FormattedSession
) => {
  if (
    broadcastState &&
    broadcastState?.session.id === formattedSession.id &&
    broadcastState.sessionControl &&
    broadcastState.sessionControl.sides &&
    broadcastState.sessionControl.sides[0].id &&
    broadcastState.sessionControl.sides[0].id !== '0'
  ) {
    return broadcastState.sessionControl.sides.map((side) => {
      const team = formattedSession.teams.map[side.id]
      return team
    })
  } else {
    return formattedSession.teams.list
  }
}

export const getPlayerSessionByHardwareSerial = (playersSessions, serial) => {
  return playersSessions.find((playerSession) => {
    return playerSession.tag && playerSession.tag.serial === serial
  })
}

export function formatSessionDate(unixTime) {
  if (typeof unixTime !== 'number') {
    return null // Return null if the input is not a valid Unix timestamp
  }
  const zeroIt = (num: number): string => {
    if (num < 10) {
      return `0${num}`
    }
    return `${num}`
  }

  const date = new Date(unixTime)
  const year = date.getFullYear()

  const month = zeroIt(date.getMonth() + 1)
  const day = zeroIt(date.getDate())
  const hour = zeroIt(date.getHours())
  const minute = zeroIt(date.getMinutes())
  const second = zeroIt(date.getSeconds())

  return `${day}${month}${year}-${hour}${minute}${second}`
}

export const getPlayerSessionFromShirt = (
  number,
  playersSessions,
  teamInView
) => {
  return playersSessions.find((player) => {
    return player.number === number && player.teamId === teamInView
  })
}

export function getPlayerSessionFromTagId(hardwareId, playersSessions) {
  return playersSessions.find((playerSession) => {
    if (playerSession.tag) {
      return playerSession.tag.id === hardwareId
    } else {
      return false
    }
  })
}

// Session Setup Status //
export type StatusCheck = {
  type: 'error' | 'warning'
  message: string
}

export const getSessionSetupStatus = (
  formattedSession: FormattedSession,
  formattedHardware: FormattedHardware,
  sessionBroadcastIntegrationCompletion: SessionBroadcastIntergrationCompletion
) => {
  const status = {
    broadcastIntegration: {
      type: 'error',
      message: null
    },
    playersSessions: {
      type: 'error',
      message: null
    },
    hardwareQA: {
      type: 'warning',
      message: null
    },
    offlineBalls: {
      type: 'warning',
      message: null
    }
  }

  // Remove devices that are not included in formatted hardware (not being sent by the server)
  const playersSessionsWithHardwareList =
    formattedSession.playersSessions.byHardwareId.list.filter(
      (playerSession) => {
        return formattedHardware.devices.map[playerSession.hardwareId]
      }
    )

  // Check if broadcast integration is complete
  if (!sessionBroadcastIntegrationCompletion.isComplete) {
    status.broadcastIntegration.message =
      sessionBroadcastIntegrationCompletion.errors[0]
  }

  // Check if enough tags are selected
  if (playersSessionsWithHardwareList.length < 1) {
    status.playersSessions.message = 'Not enough tags selected'
  }

  // QA check on devices
  const qaFailDeviceList = playersSessionsWithHardwareList
    .filter((playerSession) => {
      const device = formattedHardware.devices.map[playerSession.hardwareId]
      if (!device) return false
      return (
        device.reports.fieldQA.passed === false ||
        device.reports.hwQA.passed === false ||
        device.reports.investigate.passed === true ||
        device.reports.retired.passed === true
      )
    })
    .map((playerSession) => {
      const device = formattedHardware.devices.map[playerSession.hardwareId]
      return device.serial
    })

  const generateQAMessage = (qaFailDeviceList) => {
    if (qaFailDeviceList.length === 0) return null

    if (qaFailDeviceList.length === 1) {
      return `Device ${qaFailDeviceList.join(
        ' '
      )} failed QA. Are you sure you want to start a session?`
    }

    if (qaFailDeviceList.length < 5) {
      return `These devices failed QA: ${qaFailDeviceList.join(
        ', '
      )}. Are you sure you want to start a session?`
    }

    return 'Multiple devices failed QA. Are you sure you want to start a session?'
  }

  // Check if enough tags are selected
  status.hardwareQA.message = generateQAMessage(qaFailDeviceList)

  // Check if offline balls are in the session //
  playersSessionsWithHardwareList.forEach((playerSession) => {
    const device =
      formattedHardware.types.ball.status.offline.devices.map[
        playerSession.hardwareId
      ]
    if (device)
      status.offlineBalls.message =
        'Offline balls were added to the session. Are you sure you want to start a session?'
  })

  return status
}

// Session Assignment Status //
// Check if players tags have been assigned to the session
export const hasPlayerTagsAssigned = (
  formattedSession: FormattedSession,
  formattedHarware: FormattedHardware
) => {
  return formattedSession.playersSessions.byPlayerId.list.some(
    (playerSession) => {
      return formattedHarware.devices.map[playerSession.hardwareId]
    }
  )
}

// Check if players have been assigned to the session
export const hasPlayersAssigned = (formattedSession: FormattedSession) => {
  return formattedSession.playersSessions.byPlayerId.count > 0
}

// Check if online balls have been assigned to the session
export const hasOnlineBallsAssigned = (
  formattedSession: FormattedSession,
  formattedHarware: FormattedHardware
) => {
  return formattedSession.balls.list.some((playerSession) => {
    return (
      formattedHarware.status.online.devices.map[playerSession.hardwareId] ||
      formattedHarware.status.sleep.devices.map[playerSession.hardwareId]
    )
  })
}

// Check if offline balls have been assigned to the session
export const hasOfflineBallsAssigned = (
  formattedSession: FormattedSession,
  formattedHarware: FormattedHardware
) => {
  return formattedSession.balls.list.some((playerSession) => {
    return formattedHarware.status.offline.devices.map[playerSession.hardwareId]
  })
}

// Check if pitch setup is complete
export const pitchSetupComplete = (
  formattedSession: FormattedSession
): boolean => {
  return (
    !!formattedSession.pitch.coordinates && formattedSession.anchors.count > 1
  )
}
// ================================= //

// Get blank session based on permissions //
export const getBlankSession = ({
  isExternalSoccerTraining
}: AppTypeCheck): RawSessionData => {
  return {
    id: null,
    startTime: moment(new Date().getTime()).startOf('day').valueOf() / 1000,
    endTime: null,
    name: '',
    playersSessions: [],
    teamsSessions: [],
    pitch: null,
    type: sessionTypes.items.match.value,
    subType: isExternalSoccerTraining
      ? subSessionTypes.items.broadcast.value
      : subSessionTypes.items.dataGathering.value,
    mode: sessionModeTypes.items.live.value,
    sportType: isExternalSoccerTraining
      ? sportTypeKey.soccer
      : sportTypeKey.rugbyUnion,
    state: null,
    pitchId: null,
    womensMode: false,
    gender: 'male' as const,
    uploadStatus: null,
    locationName: null,
    hostname: null,
    sessionControl: null,
    officiatingAlgosEnable: false
  }
}
