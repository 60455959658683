import { useCallback, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import {
  formatSessionData,
  getBlankSession,
  getEmptyGroup,
  getHighlightedSide,
  getSessionSetupStatus,
  getSessionState,
  getSessionTeamsArray,
  getSessionTeamSides,
  hasOfflineBallsAssigned,
  hasOnlineBallsAssigned,
  hasPlayersAssigned,
  hasPlayerTagsAssigned,
  isActiveSessionOnDevice,
  isMatch,
  isTraining,
  pitchSetupComplete
} from './functions'
import { usePlayers } from '../players/hooks'
import {
  FormattedPlayerSession,
  FormattedSession,
  RawTeamSessionData,
  SessionConfig
} from './types'
import { useOfficiationTeam, useTeams } from '../teams/hooks'
import { useOrganisations } from '../organisations/hooks'
import { getSport } from '../sports/functions'
import {
  addPlayerSession,
  createSession,
  downloadDataFromSessionEndpoint,
  downloadFlights,
  downloadPitchForSession,
  getScoreboard,
  getSession,
  removeBallFromSession,
  removePlayerSession,
  setSession,
  startSession,
  updateAutoSleep,
  updatePlayerSession,
  updateSession
} from './actions'
import { useEvents } from '../events/hooks'
import { getAussieRulesEventTypeGroup } from '../events/aussie_rules/data_types'
import { getMetricTypeGroup } from '../metrics/data_types'
import { useFormattedHardware } from '../hardware/hooks'
import { useBroadcastIntegrationSessionState } from '../broadcast_integration/hooks'
import { toggleModal } from '../../ui/modal/actions'
import { setInfo } from '../../ui/info/actions'
import { setError } from '../../ui/error/actions'
import { FormattedHardware } from '../hardware/types'
import { setRedirect } from '../../ui/router/actions'
import { ConfirmationRequest } from '../../components/ConfirmationRequest/ConfirmationRequest'
import * as SessionScreen from '../../views/Session/config'
import DownloadForm from '../../components/Forms/DownloadForm'
import { usePitches } from '../pitches/hooks'
import { addItemToGroup, Group } from '../types'
import { usePlayerFilter } from '../players/filter'
import { encodeHardwareId } from '../../utils/encoding'
import { useBroadcasting } from '../broadcasting/hooks'
import { getPossessionSide } from '../broadcasting/functions'
import { getPlayerNumber } from '../../components/Player/SessionPlayer/SessionPlayer'
import { useAppTypeCheck } from '../user/hooks'
import { useHardwareFilter } from '../hardware/filter'
import { isSportType } from '../sports/data_types'

export const useSessions = () => {
  const sessions = useAppSelector((state) => state.sessions)
  return sessions
}

export const useSelectedFormattedSession = (): FormattedSession => {
  const { selectedId } = useSessions()
  return useFormattedSession(selectedId)
}

export const useFormattedSession = (
  sessionId: string | 'setup'
): FormattedSession => {
  const dispatch = useAppDispatch()
  const sessions = useSessions()
  const teams = useTeams()
  const players = usePlayers()
  const pitches = usePitches()
  const session = sessions.rawData[sessionId]
  const officiationTeam = useOfficiationTeam()

  // Permissions //
  const appTypeCheck = useAppTypeCheck()
  // =================== //

  // Set blank setup session based on permissions //
  if (!session && sessionId === 'setup') {
    dispatch(setSession(getBlankSession(appTypeCheck), 'setup'))
  }

  // Allow Keyboard shortcuts
  const isKeyboardShortcutEnabled = sessions.isKeyboardShortcutEnabled
  // =================== //

  return useMemo(() => {
    if (session) {
      const live = sessions.activeSession?.id === session.id

      return formatSessionData(
        session,
        teams.rawData,
        players.rawData,
        live,
        pitches.inUse,
        officiationTeam,
        isKeyboardShortcutEnabled
      )
    }
    return null
  }, [
    sessions.activeSession,
    session,
    teams.rawData,
    players.rawData,
    pitches.inUse,
    officiationTeam,
    isKeyboardShortcutEnabled
  ])
}

export const useSessionTeamSides = (sessionId) => {
  const { state } = useBroadcasting()
  const formattedSession = useFormattedSession(sessionId)

  const sessionTeamSides = useMemo(() => {
    if (formattedSession) {
      return getSessionTeamSides(state, formattedSession)
    }
  }, [formattedSession, state])

  return {
    sessionTeamSides,
    highlightedSide: getPossessionSide(state),
    possession: state.sessionControl.possession
  }
}

export const useSession = (): SessionConfig => {
  // Store //
  const sessions = useSessions()
  const players = usePlayers()
  const teams = useTeams()
  const organisations = useOrganisations()

  const sessionConfig = useMemo(() => {
    return getSessionState(sessions, players, teams, organisations)
  }, [sessions.selected])

  const { session } = sessionConfig

  const sessionTeamsArray = useMemo(() => {
    return getSessionTeamsArray(session, organisations, teams)
  }, [sessions.selected.sessionControl])

  const highlightedSide = useMemo(() => {
    return getHighlightedSide(sessions.selected)
  }, [sessions.selected.sessionControl])

  // Is session active on device
  const live = isActiveSessionOnDevice(session.id, sessions)

  // Get sport from session sportType
  const sport = getSport(session.sportType)

  const getPlayerSession = useCallback(
    (playerSessionId) => {
      return session.playersSessions.find((playerSession) => {
        return playerSession.id === playerSessionId
      })
    },
    [session]
  )

  const isTrainingMode = isTraining(session.type)

  const isMatchMode = isMatch(session.type)

  const isKeyboardShortcutEnabled = sessions.isKeyboardShortcutEnabled

  // Event Types //
  const eventTypes = sport.props.eventTypes
  // Flight //
  const flightTypes = sport.props.eventTypes.items.flight.props.types
  const flightMetrics = sport.props.eventTypes.items.flight.props.metricTypes
  // Game //
  const gameEventTypes = sport.props.eventTypes.items.game.props.types
  const gameEventMetrics = sport.props.eventTypes.items.game.props.metricTypes
  // Time //
  const timeEventTypes = sport.props.eventTypes.items.time.props.types
  // Australian Rules //
  const australianRulesEventTypes =
    sport.props.eventTypes.items.aussieRules?.props.types ||
    getAussieRulesEventTypeGroup({})
  const australianRulesEventMetrics =
    sport.props.eventTypes.items.aussieRules?.props.metricTypes ||
    getMetricTypeGroup({})

  return {
    ...sessionConfig,
    sessionTeamsArray,
    highlightedSide,
    live,
    isTrainingMode,
    isMatchMode,
    isKeyboardShortcutEnabled,

    // Types
    sport,
    // TODO: refactor needed here
    // sessionMode,
    // sessionType,
    // sessionSubType,
    eventTypes,
    flightTypes,
    flightMetrics,
    timeEventTypes,
    gameEventTypes,
    gameEventMetrics,
    australianRulesEventTypes,
    australianRulesEventMetrics,
    // sportEventTypes,

    getPlayerSession
  }
}

export const useScoreboard = (sessionId: string) => {
  const dispatch = useAppDispatch()
  const events = useEvents()
  const { scoreboard } = useSessions()

  useEffect(() => {
    if (sessionId) {
      dispatch(getScoreboard(sessionId))
    }
  }, [sessionId, events.rawData])

  return { scoreboard }
}

export const useAutoSleep = () => {
  const dispatch = useAppDispatch()

  const { autoSleepEnabled } = useSessions()

  const toggleAutoSleep = useCallback(() => {
    dispatch(updateAutoSleep(!autoSleepEnabled))
  }, [autoSleepEnabled])

  const autoSleepToggleText = autoSleepEnabled
    ? 'Auto-Sleep: ON'
    : 'Auto-Sleep: OFF'

  const autoSleepText = autoSleepEnabled
    ? 'Auto-Sleep Enabled'
    : 'Auto-Sleep Disabled'

  return {
    autoSleepEnabled,
    autoSleepToggleText,
    toggleAutoSleep
  }
}

// Session Setup Status //

export const useSessionSetupStatus = () => {
  const formattedSession = useFormattedSession('setup')
  const formattedHardware = useFormattedHardware()
  const { sessionIntegrationCompletion } = useBroadcastIntegrationSessionState()

  return useMemo(() => {
    return getSessionSetupStatus(
      formattedSession,
      formattedHardware,
      sessionIntegrationCompletion
    )
  }, [formattedSession, formattedHardware, sessionIntegrationCompletion])
}

export const getSessionDeviceByPlayerId = (
  formattedSession: FormattedSession,
  formattedHardware: FormattedHardware,
  playerId: string
) => {
  const playerSession =
    formattedSession.playersSessions.byPlayerId.map[playerId]
  if (!playerSession) {
    return null
  }
  const device = formattedHardware.devices.map[playerSession.hardwareId]
  return device
}

export const useSessionDeviceByPlayerId = (playerId) => {
  const formattedSession = useFormattedSession('setup')
  const formattedHardware = useFormattedHardware()

  return useMemo(() => {
    return getSessionDeviceByPlayerId(
      formattedSession,
      formattedHardware,
      playerId
    )
  }, [formattedSession, formattedHardware, playerId])
}

export const getSessionDeviceByShirtNumber = (
  formattedSession: FormattedSession,
  formattedHardware: FormattedHardware,
  number: number,
  teamId: string
) => {
  const playerSession =
    formattedSession.playersSessions.byNumber.map[teamId][number]
  if (!playerSession) {
    return null
  }
  const device = formattedHardware.devices.map[playerSession.hardwareId]
  return device
}

export const useSessionDeviceByShirtNumber = (
  number: number,
  teamId: string
) => {
  const formattedSession = useFormattedSession('setup')
  const formattedHardware = useFormattedHardware()

  return useMemo(() => {
    return getSessionDeviceByShirtNumber(
      formattedSession,
      formattedHardware,
      number,
      teamId
    )
  }, [formattedSession, formattedHardware, number, teamId])
}

export const useStartSession = () => {
  const dispatch = useAppDispatch()

  const assignmentStatus = useSessionAssignmentStatus()
  const formattedSession = useFormattedSession('setup')
  const formattedHardware = useFormattedHardware()
  const { sessionIntegrationCompletion } = useBroadcastIntegrationSessionState()

  const sessionReadyToStart =
    ((assignmentStatus.shirts && assignmentStatus.tags) ||
      assignmentStatus.balls ||
      assignmentStatus.offlineBalls) &&
    assignmentStatus.pitch

  const runChecksAndStartSession = useCallback(() => {
    const status = getSessionSetupStatus(
      formattedSession,
      formattedHardware,
      sessionIntegrationCompletion
    )

    if (status.broadcastIntegration.message) {
      return dispatch(
        setError({
          message: status.broadcastIntegration.message
        })
      )
    }

    if (status.playersSessions.message) {
      return dispatch(
        setError({
          message: status.playersSessions.message
        })
      )
    }

    const createAndStartSession = () => {
      dispatch(
        createSession(formattedSession.sessionData, (data) => {
          dispatch(
            startSession(data, () => {
              dispatch(setRedirect(SessionScreen.path))
              dispatch(toggleModal({}))
            })
          )
        })
      )
    }

    const checkQAAndOfflineBalls = () => {
      if (status.hardwareQA.message) {
        dispatch(
          setInfo({
            message: status.hardwareQA.message,
            header: 'QA Warning',
            proceed: () => {
              if (status.offlineBalls.message) {
                // Add a small delay before displaying the offline balls modal
                setTimeout(() => {
                  dispatch(
                    setInfo({
                      message: status.offlineBalls.message,
                      header: 'Offline Balls',
                      proceed: () => createAndStartSession()
                    })
                  )
                }, 100)
              } else {
                createAndStartSession()
              }
            }
          })
        )
      } else if (status.offlineBalls.message) {
        dispatch(
          setInfo({
            message: status.offlineBalls.message,
            header: 'Offline Balls',
            proceed: () => createAndStartSession()
          })
        )
      } else {
        createAndStartSession()
      }
    }

    const checkHomeTeam = () => {
      // Switch home and away teams
      const handleHomeTeamChange = () => {
        const newRawHomeTeamSession: RawTeamSessionData = {
          ...formattedSession.teamsSessions.map[formattedSession.homeTeam.id]
            .rawData,
          homeAway: 'HOME'
        }
        const newRawAwayTeamSession: RawTeamSessionData = {
          ...formattedSession.teamsSessions.map[formattedSession.awayTeam.id]
            .rawData,
          homeAway: 'AWAY'
        }

        const newRawTeamsSessions = [
          newRawHomeTeamSession,
          newRawAwayTeamSession
        ]

        dispatch(
          updateSession({
            teamsSessions: newRawTeamsSessions
          })
        )
        dispatch(toggleModal({}))
      }

      dispatch(
        toggleModal({
          active: true,
          wrapper: true,
          height: '10%',
          ChildComponent: ConfirmationRequest,
          title: `Confirm home team - ${formattedSession.homeTeam.name} - is correct`,
          handleProceed: () => {
            dispatch(toggleModal({}))
            checkQAAndOfflineBalls()
          },
          handleClose: () => handleHomeTeamChange(),
          className: 'modalSmall'
        })
      )
    }

    if (formattedSession.homeSideCheck) {
      checkHomeTeam()
    } else {
      checkQAAndOfflineBalls()
    }
  }, [
    formattedSession,
    formattedHardware,
    sessionIntegrationCompletion,
    dispatch
  ])

  return { runChecksAndStartSession, sessionReadyToStart }
}

// Session Setup Assignment Status //
export const useSessionAssignmentStatus = () => {
  const formattedSession = useFormattedSession('setup')
  const formattedHardware = useFormattedHardware()
  return useMemo(() => {
    const tags = hasPlayerTagsAssigned(formattedSession, formattedHardware)
    const shirts = hasPlayersAssigned(formattedSession)
    const balls = hasOnlineBallsAssigned(formattedSession, formattedHardware)
    const offlineBalls = hasOfflineBallsAssigned(
      formattedSession,
      formattedHardware
    )
    const pitch = pitchSetupComplete(formattedSession)

    return {
      tags,
      shirts,
      balls,
      offlineBalls,
      pitch
    }
  }, [formattedSession, formattedHardware])
}

// Resume Active Session //
export const useResumeActiveSession = () => {
  const dispatch = useAppDispatch()
  const teams = useTeams()
  const players = usePlayers()
  const officiationTeam = useOfficiationTeam()
  const { activeSession } = useSessions()
  const getActiveSession = useCallback(() => {
    if (!activeSession?.teamsSessions) {
      return
    }
    const formattedSession = formatSessionData(
      activeSession,
      teams.rawData,
      players.rawData,
      true,
      null,
      officiationTeam
    )
    dispatch(
      getSession(formattedSession, () => {
        dispatch(setRedirect(SessionScreen.path))
      })
    )
  }, [activeSession, teams.rawData, players.rawData, dispatch, officiationTeam])
  return getActiveSession
}

// Session Download //
export type SessionDownloadType =
  | 'xml'
  | 'csv'
  | 'ball_position'
  | 'raw_session'
  | 'pitch'
  | 'player_breakdown'
  | 'player_position'

export const useSessionDownload = (session: FormattedSession) => {
  const dispatch = useAppDispatch()

  const downloadSessionData = useCallback(
    (type: SessionDownloadType, formOffset: string, team: string) => {
      switch (type) {
        case 'ball_position':
        case 'raw_session':
        case 'player_breakdown':
        case 'player_position':
          dispatch(downloadDataFromSessionEndpoint(session.id, type))
          break
        case 'pitch':
          dispatch(downloadPitchForSession(session.id, session.name))
          break
        default: {
          const data = {
            xml: type === 'xml',
            csv: type === 'csv',
            offset: isNaN(parseFloat(formOffset)) ? 0 : parseFloat(formOffset),
            team: team || null
          }
          dispatch(downloadFlights(data, session.id))
          break
        }
      }
    },
    [session, dispatch]
  )

  const openDownloadModal = useCallback(() => {
    dispatch(
      toggleModal({
        active: true,
        type: 'confirm',
        handleProceed: (
          type: SessionDownloadType,
          formOffset: string,
          teamId: string
        ) => {
          downloadSessionData(type, formOffset, teamId)
          dispatch(toggleModal({}))
        },
        ChildComponent: (props) => (
          <DownloadForm
            {...props}
            isAdmin={true}
            teams={session.teamsSessions}
          />
        ),
        message: 'Would you like to download the session data in .csv format?',
        className: 'modalSmall',
        handleClose: () => {
          dispatch(toggleModal({}))
        }
      })
    )
  }, [downloadSessionData, dispatch, session])

  return { openDownloadModal, downloadSessionData }
}

export const generateRandomNumberNotInList = (
  list: number[],
  max: number,
  initialNumber: number
) => {
  let number = initialNumber
  let isValidNumber = false
  while (!isValidNumber) {
    if (number && !list.includes(number)) {
      isValidNumber = true
    }
    if (!isValidNumber) {
      number = Math.floor(Math.random() * max)
    }
  }
  return number
}

// Get the lowest available number greater than 0 from an array of numbers
export const getFirstAvailableNumber = (list: number[]) => {
  let number = 1
  while (list.includes(number)) {
    number++
  }
  return number
}

// Session Assignment //
export const usePlayerAssignmentFunctions = (teamId: string) => {
  const dispatch = useAppDispatch()

  const formattedSession = useFormattedSession('setup')
  const { playersSessions, players, sport } = formattedSession
  const { filteredPlayers } = usePlayerFilter(
    {
      team: [teamId]
    },
    'setup'
  )
  const { filteredHardware } = useHardwareFilter(
    {
      assigned: 'notAssigned',
      team: teamId
    },
    'setup'
  )

  // Add all players from a team to the session
  const addAllTeamsPlayersToSession = useCallback(() => {
    const teamPlayersSessions = playersSessions.byNumber[teamId]
    const numbersUsed = teamPlayersSessions.list.map((playerSession) => {
      return playerSession.number
    })

    // loop through players not assigned to session and create a player session
    filteredPlayers.list
      .filter((player) => {
        return !playersSessions.byPlayerId.map[player.id]
      })
      .forEach((player, index) => {
        const playerNumber = sport.props.features.positionNumbers
          ? getFirstAvailableNumber(numbersUsed)
          : generateRandomNumberNotInList(numbersUsed, 100, player.number)

        numbersUsed.push(playerNumber)

        dispatch(
          addPlayerSession({
            id: null,
            sessionId: null,
            tag: null,
            player: player.rawData,
            playerId: player.id,
            teamId: teamId,
            number: playerNumber
          })
        )
      })
    // =================== //
  }, [players, playersSessions, dispatch, teamId])

  // Remove all players from Session
  const removeAllTeamsPlayersFromSession = useCallback(() => {
    playersSessions.byPlayerId.list.forEach((playerSession) => {
      if (playerSession.teamId === teamId) {
        dispatch(removePlayerSession(playerSession.playerId))
      }
    })
  }, [playersSessions, dispatch, teamId])

  const TAG_ASSIGN_LIMIT =
    sport.props.features.maxPlayers < 40 ? sport.props.features.maxPlayers : 40 // Set the limit for tag assignment

  // Randomly assign remaining tags to random players
  const randomTagAssign = useCallback(() => {
    // Get teams players sessions
    const teamPlayersSessions = playersSessions.byNumber[teamId]
    const teamPlayersSessionsWithHardware = teamPlayersSessions.list.filter(
      (playerSession) => playerSession.hardwareId
    )

    let hardwareIndex = 0

    // Loop through player sessions without hardware and assign tags
    teamPlayersSessions.list
      .filter((playerSession) => !playerSession.hardwareId)
      .forEach((playerSession) => {
        // Add tag to player session
        if (
          teamPlayersSessionsWithHardware.length + hardwareIndex <
          TAG_ASSIGN_LIMIT
        ) {
          const device = filteredHardware.list[hardwareIndex]
          hardwareIndex++
          if (device) {
            const tagData = {
              tag: {
                serial: device.serial,
                id: device.id,
                type: device.type.value
              }
            }
            dispatch(
              updatePlayerSession(teamId, playerSession.number, tagData, null)
            )
          }
        }
      })
    // =================== //

    // Loop through players not assigned to session and create a player session with a tag
    const numbersUsed = teamPlayersSessions.list.map((playerSession) => {
      return playerSession.number
    })

    filteredPlayers.list
      .filter((player) => {
        return !playersSessions.byPlayerId.map[player.id]
      })
      .forEach((player, index) => {
        if (
          teamPlayersSessionsWithHardware.length + hardwareIndex <
          TAG_ASSIGN_LIMIT
        ) {
          const device = filteredHardware.list[hardwareIndex]
          hardwareIndex++
          if (device) {
            const tagData = {
              serial: device.serial,
              id: device.id,
              type: device.type.value,
              productName: device.productName
            }

            const playerNumber = sport.props.features.positionNumbers
              ? getFirstAvailableNumber(numbersUsed)
              : generateRandomNumberNotInList(numbersUsed, 100, player.number)

            numbersUsed.push(playerNumber)

            dispatch(
              addPlayerSession({
                id: null,
                sessionId: null,
                tag: tagData,
                player: player.rawData,
                playerId: player.id,
                teamId: teamId,
                number: playerNumber
              })
            )
          }
        }
      })
    // =================== //
  }, [
    filteredPlayers,
    filteredHardware,
    playersSessions,
    dispatch,
    addAllTeamsPlayersToSession,
    teamId
  ])

  return {
    addAllTeamsPlayersToSession,
    removeAllTeamsPlayersFromSession,
    randomTagAssign
  }
}

export const useBallAssignmentFunctions = () => {
  const dispatch = useAppDispatch()

  const { balls } = useFormattedSession('setup')

  const formattedHardware = useFormattedHardware()

  const addAllBallsToSession = useCallback(() => {
    formattedHardware.types.ball.devices.list.forEach((device) =>
      dispatch(
        addPlayerSession({
          id: null,
          playerId: null,
          teamId: null,
          sessionId: null,
          number: null,
          player: null,
          tag: {
            id: device.id,
            serial: device.serial,
            type: device.type.value,
            productName: device.productName
          }
        })
      )
    )
  }, [formattedHardware])

  const removeAllBallsFromSession = useCallback(() => {
    balls.list.forEach((ball) =>
      dispatch(removeBallFromSession(ball.hardwareId))
    )
  }, [balls])

  return {
    addAllBallsToSession,
    removeAllBallsFromSession
  }
}

/*========== Drag and Drop =========*/
export const useTagAssignmentDragAndDrop = (sessionId: string) => {
  const dispatch = useAppDispatch()

  const {
    isTrainingMode,
    sport,
    playersSessions,
    teams,
    officiationTeam,
    isOfficiatingMode
  } = useFormattedSession(sessionId)

  const fomattedHardware = useFormattedHardware()

  const [dropZone, setDropZone] = useState(null)
  const [isDragged, setIsDragged] = useState(false)
  const [currentTagId, setcurrentTagId] = useState(null)

  // Get team ids
  const teamIds = useMemo(() => {
    if (isOfficiatingMode && officiationTeam) {
      return [...teams.list, officiationTeam].map((team) => team.id)
    } else {
      return teams.list.map((team) => team.id)
    }
  }, [teams, isOfficiatingMode, officiationTeam])

  const { filteredPlayers } = usePlayerFilter({ team: teamIds }, 'setup')

  const onTagDrag = (ev) => {
    const playersAreAssigned = playersSessions.byPlayerId.count > 0

    if (!playersAreAssigned) {
      return dispatch(
        setInfo({
          message: `Please assign 'Players' to 'Shirts' first`,
          header: 'Assignment Warning!'
        })
      )
    }
    setIsDragged(true)
    setcurrentTagId(ev.target.id)
    ev.dataTransfer.setData('id', ev.target.id)
  }

  const onPlayerDrag = (ev) => {
    setIsDragged(true)
    setcurrentTagId(ev.target.id)
    ev.dataTransfer.setData('id', ev.target.id)
  }

  const onDragOver = (ev) => {
    if (ev.target.id !== dropZone) setDropZone(ev.target.id)
    ev.stopPropagation()
    ev.preventDefault()
  }

  const onDragLeave = (ev) => {
    setIsDragged(false)
    setcurrentTagId(null)
    setDropZone(null)
    ev.stopPropagation()
    ev.preventDefault()
  }

  // Update a player session with a tag when one is dropped on a player
  const onTagDrop = useCallback(
    (ev, id, playerId) => {
      const player = filteredPlayers.map[playerId]

      setIsDragged(false)
      setcurrentTagId(null)

      if (!id) {
        id = ev.dataTransfer.getData('id')
      }

      const hardwareId = parseInt(playerId ? ev.dataTransfer.getData('id') : id)
      const targetId = ev.target.id
      const tagData = {
        tag: {
          serial: encodeHardwareId(hardwareId),
          id: hardwareId,
          type: 'PlayerTag'
        }
      }

      // If tag is dropped on a player update using player id otherwise update using shirt number
      if (
        isTrainingMode ||
        !sport.props.features.positionNumbers ||
        isOfficiatingMode
      ) {
        // Bug happening here - player is undefined
        dispatch(
          updatePlayerSession(
            player.teamId,
            null,
            tagData,
            playerId ? playerId : targetId
          )
        )
      } else {
        dispatch(
          updatePlayerSession(player.teamId, parseInt(targetId), tagData, null)
        )
      }

      setDropZone(null)
    },
    [filteredPlayers]
  )

  // Add player session when player is dropped on a shirt
  const onShirtDrop = useCallback(
    (ev, teamId) => {
      const id = ev.dataTransfer.getData('id')
      const number = ev.target.id

      const player = filteredPlayers.map[id]
      const device = fomattedHardware.devices.map[id]

      setIsDragged(false)
      setcurrentTagId(null)

      if (player) {
        dispatch(
          addPlayerSession({
            id: null,
            sessionId: null,
            tag: null,
            player,
            playerId: player.id,
            teamId: player.teamId,
            number: parseInt(number)
          })
        )
      } else if (device) {
        const tagData = {
          tag: {
            serial: device.serial,
            id: device.id,
            type: device.type.value
          }
        }
        dispatch(updatePlayerSession(teamId, parseInt(number), tagData, null))
      }

      setDropZone(null)
    },
    [filteredPlayers, fomattedHardware]
  )

  return {
    onDragLeave,
    onDragOver,
    onTagDrop,
    onShirtDrop,
    onTagDrag,
    onPlayerDrag,
    dropZone,
    isDragged,
    currentTagId,
    draggable: !isTrainingMode && sport.props.features.positionNumbers
  }
}

// Use Player Positions //
export type PlayerPosition = {
  number: number
  teamId: string
  playerSession: FormattedPlayerSession
  isInStartingLineup: boolean
}

export const usePlayerPositions = (sessionId: string) => {
  const { teams, playersSessions, sport } = useFormattedSession(sessionId)

  return useMemo(() => {
    const playerPositions: {
      [key: string]: Group<PlayerPosition, number>
    } = {}

    teams.list.forEach((team) => {
      playerPositions[team.id] = getEmptyGroup()
      for (let i = 0; i < sport.props.features.maxPlayers; i++) {
        const number = i + 1
        const playerPosition = {
          number: number,
          teamId: team.id,
          playerSession: null,
          isInStartingLineup:
            number <= (sport.props.features.noOfPlayersInStartingLineUp || 15)
        }

        // Check for player session
        const playerSession = playersSessions.byNumber[team.id].map[number]

        if (playerSession) {
          playerPosition.playerSession = playerSession
        }

        addItemToGroup(
          playerPositions[team.id],
          playerPosition,
          playerPosition.number,
          'number',
          `${playerPosition.number}`
        )
      }
    })

    return playerPositions
  }, [playersSessions, teams, sport])
}
